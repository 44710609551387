import { Logger } from '@vue-storefront/core';

import getCustomerAccessToken from '../../helpers/internals/getCustomerAccessToken';
import { Context } from '../../types';
import { IUseUserAddressesRefs } from '../types';

const updateDefaultUserAddress =
  (context: Context, refs: IUseUserAddressesRefs) => async (addressId: string) => {
    const customerAccessToken = getCustomerAccessToken(context);

    Logger.debug('useUserAddresses/updateDefaultUserAddress');

    try {
      refs.loading.value.updateDefaultUserAddress = true;

      const response = await context.$shopify.api.updateDefaultAddress({
        customerAccessToken,
        addressId,
      });

      const data = response.data?.customerDefaultAddressUpdate;

      if (data && !data.customerUserErrors.length && !response.errors) {
        refs.result.value.updateDefaultUserAddress = true;
      } else {
        const errors = data?.customerUserErrors || response.errors;

        if (errors) {
          refs.error.value.updateDefaultUserAddress = errors;

          Logger.error('useUserAddresses/updateUserAddress/error', errors);
        }
      }
    } catch (error) {
      refs.error.value.updateDefaultUserAddress = error as Error;
      Logger.error('useUserAddresses/updateDefaultUserAddress/error', error);
    } finally {
      refs.loading.value.updateDefaultUserAddress = false;
    }
  };

export default updateDefaultUserAddress;
