import { PLACEHOLDER_IMAGE_SRC } from '@composables/src/constants';
import { resizeImageURL } from '@composables/src/helpers/internals/resizeImageUrl';

export interface Option {
  name: string;
  values: string[];
}

export interface SelectedOption {
  name: string;
  value: string;
}

export interface VariantPartial {
  id: string;
  selectedOptions: SelectedOption[];
  availableForSale?: boolean;
  image: {
    originalSrc?: string;
    src?: string;
  };
}

export const findVariantByOption = (
  variants: VariantPartial[],
  optionName: string,
  optionValue: string,
): VariantPartial | undefined =>
  variants.find(({ selectedOptions }) =>
    selectedOptions.find(({ name, value }) => name === optionName && value === optionValue),
  );

interface ImageIndex {
  [key: string]: string;
}

const DEFAULT_IMAGE_INDEX: ImageIndex = { placeholder: resizeImageURL(PLACEHOLDER_IMAGE_SRC) };

export const variantImagesByColor = (
  variants: VariantPartial[],
  option: Option,
): { [key: string]: string } =>
  option.values.reduce((accum, optionValue) => {
    const variant = findVariantByOption(variants, option.name, optionValue);
    if (!variant) return accum;
    const src = variant.image?.originalSrc ?? variant.image?.src;
    if (!src) throw new Error(`No image found for variant ${variant.id}`);
    accum[optionValue] = resizeImageURL(src);
    return accum;
  }, DEFAULT_IMAGE_INDEX);
