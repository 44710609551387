import { Cart } from '@api-client/src/types';

interface IShopifyError {
  message: string;
  extensions: {
    code: string;
  };
}

interface IShopifyResponse {
  data: unknown;
  length: unknown;
  errors: IShopifyError[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logThrottledRequests = (
  requestName: string,
  params: unknown,
  response: IShopifyResponse | Cart,
  logConfig = {
    shouldLogEmptyResponse: true,
  },
): void => {
  const { shouldLogEmptyResponse } = logConfig;

  const hasErrors = response.errors && response.errors.length > 0;
  const hasEmptyResponse = Array.isArray(response)
    ? response.length === 0
    : Object.keys(response).length === 0;

  if (hasErrors) {
    response.errors.forEach((error: IShopifyError) => {
      if (
        error.extensions.code === 'THROTTLED' ||
        error.extensions.code === 'INTERNAL_SERVER_ERROR'
      ) {
        const errorMessage = error.message;
        const errorCode = error.extensions.code;

        console.error(`SHOPIFY STOREFRONT API - ${requestName}: ${errorCode}. ${errorMessage}`, {
          params: JSON.stringify(params),
          response: JSON.stringify(response),
        });
      }
    });
  } else if (hasEmptyResponse && shouldLogEmptyResponse) {
    console.error(`SHOPIFY STOREFRONT API - ${requestName}: No response.`, {
      params: JSON.stringify(params),
    });
  }
};

export default logThrottledRequests;
