





















import round from 'lodash/round';
import Slider from 'veeno';
import { IData, IOnChangeArgs } from './types';

export default {
  name: 'BaseRangeSlider',
  components: {
    Slider,
  },
  props: {
    min: {
      type: Number,
      default: 0.5,
    },
    max: {
      type: Number,
      default: 5000,
    },
    defaultMin: {
      type: Number,
      default: 0,
    },
    defaultMax: {
      type: Number,
      default: 10,
    },
    name: {
      type: String,
      default: 'Price',
    },
    prefix: {
      type: String,
      default: '$',
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  data(): IData {
    return {
      selectedMin: this.defaultMin || this.min,
      selectedMax: this.defaultMax || this.max,
    };
  },
  methods: {
    onChange({ unencoded: values }: IOnChangeArgs): void {
      const [changedMin, changedMax] = values;
      this.selectedMin = round(changedMin, 2);
      this.selectedMax = round(changedMax, 2);

      this.$emit('range-update', {
        type: 'range',
        name: this.name,
        value: {
          min: this.selectedMin,
          max: this.selectedMax,
        },
      });
    },
  },
};
