


















import { PropType } from 'vue';
import { IData } from './types';
import { STUB_CHECKBOX_LIST_PROP } from './constants';
import { ICheckboxGroupItem } from '../../atoms/BaseCheckboxGroupItem/types';
import BaseCheckboxGroupItem from '../../atoms/BaseCheckboxGroupItem/BaseCheckboxGroupItem.vue';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';

export default {
  name: 'BaseCheckboxGroup',
  components: {
    BaseCheckboxGroupItem,
    BaseButton,
  },
  props: {
    checkboxList: {
      type: Array as PropType<Array<ICheckboxGroupItem>>,
      default: (): ICheckboxGroupItem[] => STUB_CHECKBOX_LIST_PROP,
    },
    name: {
      type: String,
      default: 'Parts',
    },
    checkeredStyle: {
      type: Boolean,
      default: false,
    },
    numberOfCheckboxesToShowByDefault: {
      type: Number,
      default: 10,
    },
  },
  data(): IData {
    return {
      selectedCheckboxes: [],
      expanded: false,
    };
  },
  computed: {
    checkboxesToShow(): Array<ICheckboxGroupItem> {
      if (this.expanded) {
        return this.checkboxList;
      }
      return this.checkboxList.slice(0, this.numberOfCheckboxesToShowByDefault);
    },
  },
  methods: {
    onCheckboxChange(checkbox: ICheckboxGroupItem): void {
      if (checkbox.selected) {
        this.selectedCheckboxes = [...this.selectedCheckboxes, checkbox];
      } else {
        this.selectedCheckboxes = this.selectedCheckboxes.filter(
          (selectedCheckbox: ICheckboxGroupItem) => selectedCheckbox.name !== checkbox.name,
        );
      }

      this.$emit('update-checkbox', {
        type: 'checkbox',
        name: this.name,
        value: checkbox,
      });

      this.$emit('update-checkbox-group', {
        type: 'checkbox',
        name: this.name,
        value: this.selectedCheckboxes,
      });
    },
    toggleExpansion(): void {
      this.expanded = !this.expanded;
    },
  },
};
