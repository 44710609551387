import {
  Collection,
  IProductResult,
  ISearchParams,
  ISearchResponse,
  UserVehicleSelectionKey,
} from '@api-client/src/api/search/types';
import { useSearchFactory } from '@vue-storefront/core';

import { getFitmentAskus, getFitmentByKtypeParams } from '../getters';

const useSearch = useSearchFactory<ISearchResponse<IProductResult[]>, ISearchParams>({
  search: async (context, params) => {
    const userVehicleSelectionsCookie =
      context.$searchIO.config.app.$cookies.get(UserVehicleSelectionKey);

    const currentSelectedVehicle = userVehicleSelectionsCookie?.length
      ? userVehicleSelectionsCookie.filter((el: any) => el.selected)[0]
      : null;

    if (
      !params.ASKUs &&
      userVehicleSelectionsCookie &&
      currentSelectedVehicle?.KtypNr &&
      (params.collection === Collection.PRODUCTS || !params.collection)
    ) {
      const KtypNr = currentSelectedVehicle.KtypNr;

      /*
       *  TODO: this ASKU stuff should be in /websites getSearchParams not directly in integration
       * Make 1st request with KtyPnr to get Askus
       */
      const fitmentResponse = await context.$searchIO.api.search(getFitmentByKtypeParams(KtypNr));
      const askus = getFitmentAskus(fitmentResponse);

      // Add ASKUs to query params
      params.ASKUs = askus.join(',');
    }

    const searchResponse = await context.$searchIO.api.search(params);
    return searchResponse;
  },
});

export default useSearch;
