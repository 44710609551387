/* eslint-disable @typescript-eslint/no-explicit-any */
import { Cart, LineItem } from '@api-client/src/types';
import { AgnosticCoupon, AgnosticDiscount, AgnosticTotals } from '@vue-storefront/core';

import { Price } from '../types';
import { formatSelectedAttributeList } from './_utils';

export const getCartItems = (cart: Cart): LineItem[] => {
  return cart?.lineItems || [];
};

export const getCartItemName = (product: any): string => product?.title || "Product's name";
export const getCartItemId = (product: any): string => product?.id || '0';
export const getCartItemSlug = (product: any): string => {
  return product?.slug || '0';
};

export const getCartItemPartNumber = (product: any): string =>
  product?.variant?.partNumber?.value || '';

export const getCartItemImage = (product: any): string => {
  if (product?.variant && product?.variant?.image !== null) {
    const imgPath = product?.variant?.image?.src?.substring(
      0,
      product?.variant?.image?.src?.lastIndexOf('.'),
    );
    const imgext = product?.variant?.image?.src?.split('.')?.pop();
    const cartImg = `${imgPath}_120x120.${imgext}`;
    return cartImg;
  }
  return '';
};

export const getCartItemPrice = (product: any): Price => {
  const productPrice: Price = {
    price: null,
    compareToPrice: null,
  };

  if (product) {
    const defaultVariant = product?.variant;

    if (defaultVariant?.compareAtPriceV2) {
      productPrice.price = parseFloat(defaultVariant?.priceV2?.amount);
      productPrice.compareToPrice = parseFloat(defaultVariant?.compareAtPriceV2?.amount);
    } else {
      productPrice.price = parseFloat(defaultVariant?.priceV2?.amount);
    }
  }

  return productPrice;
};

export const getPrice = (product: any) => {
  if (getCartItemPrice(product)?.compareToPrice) {
    return getCartItemPrice(product)?.compareToPrice;
  }

  return getCartItemPrice(product)?.price;
};

export const getCompareToPrice = (product: any) => {
  if (getCartItemPrice(product)?.compareToPrice) {
    return getCartItemPrice(product)?.price;
  }

  return null;
};

export const getCartItemQuantity = (lineItem: LineItem): number => lineItem?.quantity || 0;

const isFreeSale = (lineItem: LineItem) =>
  lineItem.variant?.availableForSale && lineItem.variant.product?.totalInventory <= 0;

export const getCartItemMaximumQuantity = (
  lineItem: LineItem,
  options: { supportFreeSale: boolean } = { supportFreeSale: false },
): number => {
  return options.supportFreeSale && isFreeSale(lineItem)
    ? 100
    : lineItem?.variant?.product?.totalInventory || 0;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCartItemAttributes = (product: LineItem, filterByAttributeName?: Array<string>) => {
  const formatAttedattributeList = formatSelectedAttributeList(product?.variant?.selectedOptions);
  if (formatAttedattributeList?.length) {
    const attribArr: any = {};
    formatAttedattributeList?.forEach((attr) => {
      attribArr[attr?.name as any] = attr?.value;
    });
    return { ...attribArr };
  }
  return {};
};

export const getCartItemSku = (product: any): string => product?.variant?.sku || '-';

export const getProductTags = (lineItem: LineItem) => lineItem.variant.product.tags;

export const getCartTotals = (cart: any): AgnosticTotals => {
  const totals: AgnosticTotals = {
    total: 0,
    subtotal: 0,
    special: 0,
  };

  if (cart && cart !== null) {
    return {
      total: parseFloat(cart?.totalPriceV2?.amount),
      subtotal: parseFloat(cart?.subtotalPriceV2?.amount),
      special: parseFloat(cart?.subtotalPriceV2?.amount),
    };
  }

  return totals;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCartShippingPrice = (cart: Cart): number => 0;

export const getCartSubTotal = (cart: Cart): number => cart?.lineItemsSubtotalPrice || 0;

export const getcheckoutURL = (cart: Cart): string => {
  return cart?.webUrl || '';
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCartTotalItems = (cart: Cart): number => {
  if (cart && cart?.lineItems && cart?.lineItems?.length > 0) {
    return cart?.lineItems?.reduce((n: any, { quantity }: any) => n + quantity, 0);
  }
  return 0;
};

export const getCartTotalDiscount = (cart: Cart): number => {
  if (cart && cart?.discountApplications && cart?.discountApplications?.length > 0) {
    return cart?.discountApplications[0]?.value;
  }
  return 0;
};

export const getFormattedPrice = (price: number) => String(price);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCoupons = (cart: Cart): AgnosticCoupon[] => [];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getDiscounts = (cart: Cart): AgnosticDiscount[] => [];

const getLineItemDiscounts = (lineItem: LineItem) =>
  (lineItem.discountAllocations ?? [])
    .map(({ allocatedAmount }: any) => parseFloat(allocatedAmount?.amount ?? 0))
    .reduce((acc: number, val: number) => acc + val, 0);

export const getVendor = (lineItem: LineItem): string => {
  return lineItem?.variant?.product?.vendor || '';
};

const cartGetters = {
  getTotals: getCartTotals,
  getShippingPrice: getCartShippingPrice,
  getItems: getCartItems,
  getItemName: getCartItemName,
  getItemImage: getCartItemImage,
  getItemId: getCartItemId,
  getItemSlug: getCartItemSlug,
  getItemPrice: getCartItemPrice,
  getItemAttributes: getCartItemAttributes,
  getItemSku: getCartItemSku,
  getFormattedPrice,
  getTotalItems: getCartTotalItems,
  getTotalDiscount: getCartTotalDiscount,
  getCoupons,
  getDiscounts,
  getcheckoutURL,
  getSubTotal: getCartSubTotal,
  getPrice,
  getCompareToPrice,
  getCartItemQuantity,
  getCartItemMaximumQuantity,
  getVendor,
  getPartNumber: getCartItemPartNumber,
  getProductTags,
  getLineItemDiscounts,
};

export default cartGetters;
