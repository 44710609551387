import getAndEnhanceProducts from '@composables/src/helpers/internals/getAndEnhanceProducts';
import { Context, Logger } from '@vue-storefront/core';

import { ComponentReference } from './types';
import { isFulfilled } from './types';

export default async function concurrentResolveProductsByCategory(
  context: Context,
  shopProdByCatRefs: ComponentReference[],
) {
  // iterate over the matches and find the data. if no data set null
  const shopProdByCatRefsWithDataPromises = await Promise.allSettled(
    shopProdByCatRefs.map(async (reference) => {
      const data = await getShopifyProductsByCategory(
        context,
        reference.component?.shopify_category?.items?.[0]?.name,
        parseInt(reference.component?.product_count),
        reference.component?.sort_key,
      );

      if (!data) {
        Logger.warn(
          `concurrentResolveProductsByCategory() Couldnt resolve data for: ${reference.component?.shopify_category?.items?.[0]?.name} :\n Possibly Stale Shopify reference`,
        );
      }

      return {
        ...reference,
        data,
      };
    }),
  );

  // filter out rejected promises
  const shopProdByCatRefsWithData = shopProdByCatRefsWithDataPromises
    .filter((promise) => isFulfilled(promise))
    .map(
      (fulfilledPromise) => (fulfilledPromise as PromiseFulfilledResult<ComponentReference>).value,
    );

  const shopProdByCatData = shopProdByCatRefsWithData.filter((ref) => ref.data);

  return shopProdByCatData;
}

async function getShopifyProductsByCategory(
  context: Context,
  collectionTitle: string,
  first: number,
  sortKey = 'COLLECTION_DEFAULT',
) {
  const response = await context.$shopify.api.getProductsByCollectionTitle({
    collectionTitle,
    first,
    sortKey,
  });
  return getAndEnhanceProducts(response.data?.collections?.edges[0]?.node?.products);
}
