import { ISearchIOAggregation, ISearchResponse } from '../types';

const getVehicleSelectionMakeOptions = (response: ISearchResponse) => {
  const makes =
    (response?.aggregateFilters?.['count.Make'] as ISearchIOAggregation)?.count?.counts || {};

  return Object.keys(makes).map((key: string) => {
    return {
      title: key,
      value: key.toLowerCase(),
    };
  });
};

export default getVehicleSelectionMakeOptions;
