/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */
import { Ref, ref } from '@nuxtjs/composition-api';
import { Context, useWishlistFactory, UseWishlistFactoryParams } from '@vue-storefront/core';

import { Product, Wishlist, WishlistProduct } from '../types';

export const wishlist: Ref<Wishlist | null> = ref(null);

const params: UseWishlistFactoryParams<Wishlist, WishlistProduct, Product> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  load: async (context: Context) => {
    console.log('Mocked: loadWishlist');
    return {};
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addItem: async (context: Context, { currentWishlist, product }: any) => {
    console.log('Mocked: addToWishlist');
    return {};
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeItem: async (context: Context, { currentWishlist, product }: any) => {
    console.log('Mocked: removeFromWishlist');
    return {};
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clear: async (context: Context, { currentWishlist }: any) => {
    console.log('Mocked: clearWishlist');
    return {};
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isInWishlist: (context: Context, { currentWishlist }: any) => {
    console.log('Mocked: isInWishlist');
    return false;
  },
};

const useWishlist = useWishlistFactory<Wishlist, WishlistProduct, Product>(params);

export default useWishlist;
