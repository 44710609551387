/* istanbul ignore file */
import { Context, useUserOrderFactory, UseUserOrderFactoryParams } from '@vue-storefront/core';

import { OrderSearchParams, OrdersResponse } from '../types';

const params: UseUserOrderFactoryParams<OrdersResponse, OrderSearchParams> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  searchOrders: async (context: Context, params: OrderSearchParams): Promise<OrdersResponse> => {
    const appIdentifier = context.$shopify.config.app.$config.appIdentifier;
    const token = context.$shopify.config.app.$cookies.get(`${appIdentifier}_token`);
    const result = await context.$shopify.api.fetchOrders(token);
    const orders = { data: [], total: 0 };
    if (result) {
      orders.data = result.customer.orders;
      orders.total = result.customer.orders.length;
      return orders;
    }
    return orders;
  },
};

const useUserOrders = useUserOrderFactory<OrdersResponse, OrderSearchParams>(params);

export default useUserOrders;
