import { ResponseStatus } from '@api-client/src/types';

export enum IAddressType {
  POSTAL_CODE = 'postal_code',
  COUNTRY = 'country',
  ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1',
  LOCALITY = 'locality',
}

export interface IAddressComponent {
  long_name: string;
  short_name: string;
  types: IAddressType[];
}

export interface IGetPlaceDetailsResponse {
  result?: {
    address_components?: IAddressComponent[];
    formatted_address?: string;
    geometry?: {
      location?: {
        lat: number;
        lng: number;
      };
    };
    name?: string;
    place_id?: string;
  };
  status?: ResponseStatus;
}
