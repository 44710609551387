import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import getQuote from './getQuote';
import { UseQuoteError, UseQuoteLoading, UseQuoteResult } from './types';

const useQuote = (id: string) => {
  const context = useVSFContext();

  const loading = sharedRef(false, `useQuote/loading-${id}`);
  const result = sharedRef(null, `useQuote-${id}`);
  const error = sharedRef(null, `useQuote/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    getQuote: getQuote(context, refs, id),
    clearQuote: () => {
      loading.value = false;
      result.value = null;
      error.value = null;
    },
    loading: computed<UseQuoteLoading>(() => loading.value),
    result: computed<UseQuoteResult>(() => result.value),
    error: computed<UseQuoteError>(() => error.value),
  };
};

export default useQuote;
