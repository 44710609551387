


















import BaseCheckoutAccountEmail from '../../molecules/BaseCheckoutAccountEmail/BaseCheckoutAccountEmail.vue';
import BaseCheckoutAccountLogin from '../../molecules/BaseCheckoutAccountLogin/BaseCheckoutAccountLogin.vue';
import { IOnLoginArgs } from './types';

export default {
  name: 'BaseCheckoutEmailSignIn',
  components: {
    BaseCheckoutAccountEmail,
    BaseCheckoutAccountLogin,
  },
  props: {
    hasSubmittedEmail: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: '',
    },
    forgotPasswordLink: {
      type: String,
      default: '#',
    },
  },
  methods: {
    onEmailSubmit(email: string): void {
      this.$emit('email-submit', email);
    },
    onLogin(loginValues: IOnLoginArgs): void {
      this.$emit('login', loginValues);
    },
    onContinueAsGuest(): void {
      this.$emit('continue-as-guest');
    },
    onBackToEmail(): void {
      this.$emit('back-to-email');
    },
  },
};
