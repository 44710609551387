






export default {
  name: 'BaseContainer',
  props: {
    maxWidth: {
      type: String,
      default: 'xxl',
    },
  },
};
