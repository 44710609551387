import { ISearchIOAggregation, ISearchResponse } from '../types';

const getVehicleSelectionModelOptions = (response: ISearchResponse) => {
  const models =
    (response?.aggregateFilters?.['count.Model'] as ISearchIOAggregation)?.count?.counts || {};
  return Object.keys(models).map((key: string) => {
    return {
      title: key,
      value: key.toLowerCase(),
    };
  });
};

export default getVehicleSelectionModelOptions;
