import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import activateAccount from './activateAccount';
import {
  UseActivateAccountError,
  UseActivateAccountLoading,
  UseActivateAccountResult,
} from './types';

const useActivateAccount = () => {
  const context = useVSFContext();

  const loading = sharedRef(false, `useActivateAccount/loading`);
  const result = sharedRef(null, `useActivateAccount`);
  const error = sharedRef(null, `useActivateAccount/error`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    activateAccount: activateAccount(context, refs),
    loading: computed<UseActivateAccountLoading>(() => loading.value),
    result: computed<UseActivateAccountResult>(() => result.value),
    error: computed<UseActivateAccountError>(() => error.value),
  };
};

export default useActivateAccount;
