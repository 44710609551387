import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import getPlaceDetails from './getPlaceDetails';
import { UsePlaceDetailsError, UsePlaceDetailsLoading, UsePlaceDetailsResult } from './types';

const usePlaceDetails = (id: string) => {
  const context = useVSFContext();

  const loading = sharedRef(false, `usePlaceDetails/loading-${id}`);
  const result = sharedRef(null, `usePlaceDetails-${id}`);
  const error = sharedRef(null, `usePlaceDetails/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    getPlaceDetails: getPlaceDetails(context, refs, id),
    loading: computed<UsePlaceDetailsLoading>(() => loading.value),
    result: computed<UsePlaceDetailsResult>(() => result.value),
    error: computed<UsePlaceDetailsError>(() => error.value),
  };
};

export default usePlaceDetails;
