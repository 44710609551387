/* eslint-disable @typescript-eslint/no-explicit-any */
import getAndEnhanceProducts from '@composables/src/helpers/internals/getAndEnhanceProducts';
import {
  Context,
  ProductsSearchParams,
  useProductFactory,
  UseProductFactoryParams,
} from '@vue-storefront/core';

const params: UseProductFactoryParams<any, any> = {
  productsSearch: async (context: Context, params: ProductsSearchParams): Promise<any> => {
    const response = await context.$shopify.api.getProductsByCollectionTitleWithPagination(params);
    return {
      products: getAndEnhanceProducts(response.data?.collections?.edges[0]?.node?.products),
      pageInfo: response.data?.collections?.edges[0]?.node?.products?.pageInfo,
    };
  },
};

const useProductsByCategoryTitleWithPagination = useProductFactory<any, any>(params);

export default useProductsByCategoryTitleWithPagination;
