import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import sendZendeskEmail from './sendZendeskEmail';
import { UseZendeskEmailError, UseZendeskEmailLoading, UseZendeskEmailResult } from './types';

const useZendeskEmail = (id: string) => {
  const context = useVSFContext();
  const loading = sharedRef(false, `useZendeskEmail/loading-${id}`);
  const result = sharedRef(null, `useZendeskEmail-${id}`);
  const error = sharedRef(null, `useZendeskEmail/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    sendZendeskEmail: sendZendeskEmail(context, refs, id),
    zendeskEmailResponse: computed<UseZendeskEmailResult>(() => result.value),
    zendeskEmailLoading: computed<UseZendeskEmailLoading>(() => loading.value),
    zendeskEmailError: computed<UseZendeskEmailError>(() => error.value),
  };
};

export default useZendeskEmail;
