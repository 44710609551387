import { Version } from '@shared/types';
import { Context, Logger } from '@vue-storefront/core';

import { getStoriesAsArray } from '../../getters';
import { IUseRelatedBlogPostsRefs } from '../types';
const MAX_BLOG_POSTS = 3;

const loadRelatedBlogPosts =
  (context: Context, refs: IUseRelatedBlogPostsRefs) =>
  async (productIds: string[], version: Version) => {
    Logger.debug('useRelatedBlogPosts/loadRelatedBlogPosts');
    try {
      refs.loading.value = true;
      const blogPostsRelatedToProductIds: any = await context.$sb.api.getContent({
        url: '/',
        version: version,
        relations: 'BlogPost.author,BlogPost.category',
        custom: {
          sort_by: `published_at:desc`,
          per_page: MAX_BLOG_POSTS,
          filter_query: {
            'featuredProduct.0.shopify_product.items.0.id': {
              in: productIds.join(','),
            },
          },
        },
      });
      refs.relatedBlogPostsTitle.value = 'Related Articles';

      const relatedBlogPostsCount: number = getStoriesAsArray(blogPostsRelatedToProductIds).length;

      let latestBlogPosts: any = {};
      if (relatedBlogPostsCount < MAX_BLOG_POSTS) {
        latestBlogPosts = await context.$sb.api.getContent({
          url: '/',
          version: version,
          relations: 'BlogPost.author,BlogPost.category',
          custom: {
            sort_by: 'published_at:desc',
            per_page: MAX_BLOG_POSTS - relatedBlogPostsCount,
            filter_query: {
              component: {
                in: 'BlogPost',
              },
            },
          },
        });
        refs.relatedBlogPostsTitle.value = 'Suggested Articles';
      }

      refs.error.value = null;
      refs.relatedBlogPosts.value = [
        ...getStoriesAsArray(blogPostsRelatedToProductIds),
        ...getStoriesAsArray(latestBlogPosts),
      ];
      if (!productIds.length) {
        refs.relatedBlogPosts.value = [];
      }
    } catch (error) {
      refs.error.value = error as Error;
      Logger.error('useRelatedBlogPosts/loadRelatedBlogPosts/error', error);
    } finally {
      refs.loading.value = false;
    }
  };

export default loadRelatedBlogPosts;
