import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import getRegoVehicles from './getRegoVehicles';
import { UseRegoVehiclesError, UseRegoVehiclesLoading, UseRegoVehiclesResult } from './types';

const useRegoVehicles = (id: string) => {
  const context = useVSFContext();
  const loading = sharedRef(false, `useRegoVehicles/loading-${id}`);
  const result = sharedRef(null, `useRegoVehicles-${id}`);
  const error = sharedRef(null, `useRegoVehicles/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    getRegoVehicles: getRegoVehicles(context, refs, id),
    regoVehiclesResponse: computed<UseRegoVehiclesResult>(() => result.value),
    regoVehiclesLoading: computed<UseRegoVehiclesLoading>(() => loading.value),
    regoVehiclesError: computed<UseRegoVehiclesError>(() => error.value),
  };
};

export default useRegoVehicles;
