






import { ICurrentYear } from './types';

export default {
  name: 'BaseCopyright',
  props: {
    businessName: {
      type: String,
      default: 'Sparesbox',
    },
    businessABN: {
      type: String,
      default: '15 168 552 323',
    },
  },
  data(): ICurrentYear {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
