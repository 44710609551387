import { Context, Logger } from '@vue-storefront/core';

import { IUseInStockNotificationRefs } from '../types';

const setNotification =
  (context: Context, refs: IUseInStockNotificationRefs, id: string) =>
  async (email: string, variantId: string) => {
    Logger.debug(`useInStockNotification/${id}/setNotification`);

    try {
      refs.result.value = null;
      refs.error.value = null;
      refs.loading.value = true;

      const isSuccess = await context.$marketing.api.setInStockNotification(email, variantId);

      if (!isSuccess) {
        refs.error.value = new Error('Sorry, the notification could not be set at this time.');
      } else {
        refs.result.value = true;
      }
    } catch (error) {
      refs.error.value = error as Error;
      Logger.error(`useInStockNotification/${id}/setNotification/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default setNotification;
