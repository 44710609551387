import { IHeaderMenuCategories } from './types';

export const STUB_HEADER_MENU_CATEGORIES: IHeaderMenuCategories = [
  {
    label: 'Accessories',
    link: '/parts/accessories',
    subCategories: [
      {
        label: 'Action Cams',
        link: '/parts/accessories/action-cams',
      },
      {
        label: 'Camping & Outdoor Equipment',
        link: '/parts/accessories/camping-and-outdoor-equipment',
      },
      {
        label: 'Car Audio & In-Car Tech',
        link: '/parts/accessories/car-audio-and-in-car-tech',
      },
      {
        label: 'Interior & Car Accessories',
        link: '/parts/accessories/interior-and-car-accessories',
      },
      {
        label: 'Motorsport & Safety Equipment',
        link: '/parts/accessories/motorsport-and-safety-equipment',
      },
      {
        label: 'Recovery Gear & Equipment',
        link: '/parts/accessories/recovery-gear-equipment',
      },
      {
        label: 'Stickers & Merchandise',
        link: '/parts/accessories/stickers-merchandise',
      },
      {
        label: 'Towing & Ute Accessories',
        link: '/parts/accessories/towing-and-ute-accessories',
      },
      {
        label: 'UHF Radios & Communication',
        link: '/parts/accessories/uhf-radios-and-communication',
      },
    ],
  },
  {
    label: 'Bodywork & Exterior',
    link: '/parts/bodywork-and-exterior',
    subCategories: [
      {
        label: 'Body Components',
        link: '/parts/bodywork-and-exterior/body-components',
      },
      {
        label: 'Car & Bike Covers',
        link: '/parts/bodywork-and-exterior/car-and-bike-covers',
      },
      {
        label: 'Door Components',
        link: '/parts/bodywork-and-exterior/door-components',
      },
      {
        label: 'Exterior Accessories',
        link: '/parts/bodywork-and-exterior/exterior-accessories',
      },
      {
        label: 'Exterior Protection',
        link: '/parts/bodywork-and-exterior/exterior-protection',
      },
      {
        label: 'Roof Racks & Roof Boxes',
        link: '/parts/bodywork-and-exterior/roof-racks-and-roof-boxes',
      },
      {
        label: 'Touch Up Paints',
        link: '/parts/bodywork-and-exterior/touch-up-paints',
      },
      {
        label: 'Vehicle Lighting',
        link: '/parts/bodywork-and-exterior/vehicle-lighting',
      },
      {
        label: 'Wheels & Tyres',
        link: '/parts/bodywork-and-exterior/wheels-tyres',
      },
      {
        label: 'Windscreen Wipers & Accessories',
        link: '/parts/bodywork-and-exterior/windscreen-wipers-and-accessories',
      },
    ],
  },
  {
    label: 'Brakes',
    link: '/brakes',
    subCategories: [
      {
        label: 'Brake Boosters',
        link: '/parts/brakes/brake-boosters',
      },
      {
        label: 'Brake Calipers & Parts',
        link: '/parts/brakes/brake-calipers-parts',
      },
      {
        label: 'Brake Cylinders',
        link: '/parts/brakes/brake-cylinders',
      },
      {
        label: 'Brake Drums',
        link: '/parts/brakes/brake-drums',
      },
      {
        label: 'Brake Lines',
        link: '/parts/brakes/brake-lines',
      },
      {
        label: 'Brake Pads',
        link: '/parts/brakes/brake-pads',
      },
      {
        label: 'Brake Rotors',
        link: '/parts/brakes/brake-rotors',
      },
      {
        label: 'Brake Sensors & ABS Modules',
        link: '/parts/brakes/brake-sensors-and-abs-modules',
      },
      {
        label: 'Brake Sets and Kits',
        link: '/parts/brakes/brake-sets',
      },
      {
        label: 'Brake Shoes',
        link: '/parts/brakes/brake-shoes',
      },
    ],
  },
  {
    label: 'Car Care',
    link: '/parts/car-care',
    subCategories: [
      {
        label: 'Accessories',
        link: '/parts/car-care/accessories',
      },
      {
        label: 'Blowers & Dryers',
        link: '/parts/car-care/blowers-dryers',
      },
      {
        label: 'Bodywork/Exterior',
        link: '/parts/car-care/bodywork-exterior',
      },
      {
        label: 'Car Care Kits',
        link: '/parts/car-care/car-care-kits',
      },
      {
        label: 'Interior Cleaning',
        link: '/parts/car-care/interior-cleaning',
      },
      {
        label: 'Marine',
        link: '/parts/car-care/marine',
      },
      {
        label: 'Polishing Machines & Equipment',
        link: '/parts/car-care/polishing-machines-equipment',
      },
      {
        label: 'Pressure Washers & High Pressure Cleaners',
        link: '/parts/car-care/pressure-washers-high-pressure-cleaners',
      },
    ],
  },
  {
    label: 'Electrical',
    link: '/parts/electrical',
    subCategories: [
      {
        label: 'Batteries & Accessories',
        link: '/parts/electrical/batteries-accessories',
      },
      {
        label: 'Cables, Fuses & Relays',
        link: '/parts/electrical/cables-fuses-relays',
      },
      {
        label: 'Globes & Bulbs',
        link: '/parts/electrical/globes-bulbs',
      },
      {
        label: 'Horns',
        link: '/parts/electrical/horns',
      },
      {
        label: 'Lighting',
        link: '/parts/electrical/lighting',
      },
      {
        label: 'Solar',
        link: '/parts/electrical/solar',
      },
      {
        label: 'Voltage Conversion',
        link: '/parts/electrical/voltage-conversion',
      },
    ],
  },
  {
    label: 'Engine Oils & Lubricants',
    link: '/parts/engine-oils-and-lubricants',
    subCategories: [
      {
        label: 'Additives',
        link: '/parts/engine-oils-and-lubricants/additives',
      },
      {
        label: 'Brake Fluid',
        link: '/parts/engine-oils-and-lubricants/brake-fluid',
      },
      {
        label: 'Coolant',
        link: '/parts/engine-oils-and-lubricants/coolant',
      },
      {
        label: 'Engine Oil',
        link: '/parts/engine-oils-and-lubricants/engine-oil',
      },
      {
        label: 'Grease',
        link: '/parts/engine-oils-and-lubricants/grease',
      },
      {
        label: 'Motorcycle & Marine Oil',
        link: '/parts/engine-oils-and-lubricants/motorcycle-and-marine-oil',
      },
      {
        label: 'Power Steering Fluid',
        link: '/parts/engine-oils-and-lubricants/power-steering-fluid',
      },
      {
        label: 'Transmission & Gear Oil',
        link: '/parts/engine-oils-and-lubricants/transmission-and-gear-oil',
      },
    ],
  },
  {
    label: 'Engine, Cooling & Drivetrain',
    link: '/parts/engine-cooling-and-drivetrain',
    subCategories: [
      {
        label: 'Auto Electrical',
        link: '/parts/engine-cooling-and-drivetrain/auto-electrical',
      },
      {
        label: 'Climate Control',
        link: '/parts/engine-cooling-and-drivetrain/climate-control',
      },
      {
        label: 'Cooling System',
        link: '/parts/engine-cooling-and-drivetrain/cooling-system',
      },
      {
        label: 'Engine Components',
        link: '/parts/engine-cooling-and-drivetrain/engine-components',
      },
      {
        label: 'Engine Management',
        link: '/parts/engine-cooling-and-drivetrain/engine-management',
      },
      {
        label: 'Exhausts',
        link: '/parts/engine-cooling-and-drivetrain/exhausts',
      },
      {
        label: 'Forced Induction',
        link: '/parts/engine-cooling-and-drivetrain/forced-induction',
      },
      {
        label: 'Fuel System',
        link: '/parts/engine-cooling-and-drivetrain/fuel-system',
      },
      {
        label: 'Ignition System',
        link: '/parts/engine-cooling-and-drivetrain/ignition-system',
      },
      {
        label: 'Oil System',
        link: '/parts/engine-cooling-and-drivetrain/oil-system',
      },
      {
        label: 'Transmission & Drivetrain',
        link: '/parts/engine-cooling-and-drivetrain/transmission-and-drivetrain',
      },
      {
        label: 'Universal Fittings, Hoses & Accessories',
        link: '/parts/engine-cooling-and-drivetrain/universal-fittings-hoses-accessories',
      },
    ],
  },
  {
    label: 'Filters',
    link: '/parts/filters',
    subCategories: [
      {
        label: 'Air Filters',
        link: '/parts/filters/air-filters',
      },
      {
        label: 'Cabin Filters',
        link: '/parts/filters/cabin-filters',
      },
      {
        label: 'Cold Air Intakes',
        link: '/parts/filters/cold-air-intakes',
      },
      {
        label: 'Diesel Particulate Filters',
        link: '/parts/filters/diesel-particulate-filters',
      },
      {
        label: 'Filter Service Kits',
        link: '/parts/filters/filter-service-kits',
      },
      {
        label: 'Fuel Filters',
        link: '/parts/filters/fuel-filters',
      },
      {
        label: 'Motorcycle Filters',
        link: '/parts/filters/motorcycle-filters',
      },
      {
        label: 'Oil Filters',
        link: '/parts/filters/oil-filters',
      },
      {
        label: 'Transmission Filters',
        link: '/parts/filters/transmission-filters',
      },
    ],
  },
  {
    label: 'Steering & Suspension',
    link: '/parts/steering-and-suspension',
    subCategories: [
      {
        label: 'Air Bag Suspension',
        link: '/parts/steering-and-suspension/air-bag-suspension',
      },
      {
        label: 'Alignment & Strut Bracing',
        link: '/parts/steering-and-suspension/alignment-strut-bracing',
      },
      {
        label: 'Anti-Roll/Sway Bars & Accessories',
        link: '/parts/steering-and-suspension/anti-roll-sway-bars-accessories',
      },
      {
        label: 'Bushes & Mounts',
        link: '/parts/steering-and-suspension/bushes-and-mounts',
      },
      {
        label: 'Coilover & Suspension Kits',
        link: '/parts/steering-and-suspension/coilover-suspension-kits',
      },
      {
        label: 'Power Steering',
        link: '/parts/steering-and-suspension/power-steering',
      },
      {
        label: 'Shock Absorbers & Springs',
        link: '/parts/steering-and-suspension/shock-absorbers-and-springs',
      },
      {
        label: 'Suspension Arms & Joints',
        link: '/parts/steering-and-suspension/suspension-arms-and-joints',
      },
      {
        label: 'Tie Rod & Steering Rack Components',
        link: '/parts/steering-and-suspension/tie-rod-and-steering-rack-components',
      },
      {
        label: 'Wheel Bearings & Hubs',
        link: '/parts/steering-and-suspension/wheel-bearings-hubs',
      },
    ],
  },
  {
    label: 'Tools',
    link: '/parts/tools',
    subCategories: [
      {
        label: 'Abrasives',
        link: '/parts/tools/abrasives',
      },
      {
        label: 'Air Tools & Compressors',
        link: '/parts/tools/air-tools-and-compressors',
      },
      {
        label: 'Automotive & Specialty',
        link: '/parts/tools/automotive-and-specialty',
      },
      {
        label: 'Bolts & Fasteners',
        link: '/parts/tools/bolts-fasteners',
      },
      {
        label: 'Diagnostic & Electrical Equipment',
        link: '/parts/tools/diagnostic-and-electrical-equipment',
      },
      {
        label: 'Garage & Workshop Equipment',
        link: '/parts/tools/garage-and-workshop-equipment',
      },
      {
        label: 'Hand Tools',
        link: '/parts/tools/hand-tools',
      },
      {
        label: 'Motorcycle Tools',
        link: '/parts/tools/motorcycle-tools',
      },
      {
        label: 'Power Tools',
        link: '/parts/tools/power-tools',
      },
      {
        label: 'Tool Kits & Storage',
        link: '/parts/tools/tool-kits-and-storage',
      },
      {
        label: 'Workshop Fluids, Sealants & Fluid Transfer',
        link: '/parts/tools/workshop-fluids-sealants-and-fluid-transfer',
      },
    ],
  },
  {
    label: 'Clearance',
    link: '/parts/clearance',
    subCategories: [
      {
        label: 'Accessories',
        link: '/parts/clearance/accessories',
      },
      {
        label: 'Bodywork & Exterior',
        link: '/parts/clearance/bodywork-exterior',
      },
      {
        label: 'Brakes',
        link: '/parts/clearance/brakes',
      },
      {
        label: 'Car Care',
        link: '/parts/clearance/car-care',
      },
      {
        label: 'Electrical',
        link: '/parts/clearance/electrical',
      },
      {
        label: 'Engine Oils & Lubricants',
        link: '/parts/clearance/engine-oils-lubricants',
      },
      {
        label: 'Engine, Cooling & Drivetrain',
        link: '/parts/clearance/engine-cooling-drivetrain',
      },
      {
        label: 'Filters',
        link: '/parts/clearance/filters',
      },
      {
        label: 'Steering & Suspension',
        link: '/parts/clearance/steering-suspension',
      },
      {
        label: 'Tools',
        link: '/parts/clearance/tools',
      },
    ],
  },
  {
    label: 'Servicing & Maintenance',
    link: '/shop/servicing',
    icon: 'keyboard_double_arrow_right',
  },
  {
    label: 'Off-Road & 4x4',
    link: '/shop/offroad-4x4',
    icon: 'keyboard_double_arrow_right',
  },
  {
    label: 'Performance',
    link: '/shop/performance',
    icon: 'keyboard_double_arrow_right',
  },
  {
    label: 'Tools & Workshop',
    link: '/shop/workshop-garage',
    icon: 'keyboard_double_arrow_right',
  },
  {
    label: 'Shop By Brand',
    link: '/brands',
    icon: 'local_offer',
  },
  {
    label: 'My Account',
    link: '/account/dashboard',
    icon: 'person_outline',
  },
  {
    label: 'Contact',
    link: '/contact',
    icon: 'mail',
  },
];
