




























import { PropType } from 'vue';
import BaseImage from '../../atoms/BaseImage/BaseImage.vue';
import BaseRadio from '../../molecules/BaseRadio/BaseRadio.vue';
import { STUB_CHECKOUT_PAYMENT_METHODS } from './constants';
import { ICheckoutPaymentMethod, ICheckoutPaymentMethods } from './types';

export default {
  name: 'BaseCheckoutPaymentMethodsSelector',
  components: {
    BaseImage,
    BaseRadio,
  },
  props: {
    paymentMethods: {
      type: Array as PropType<ICheckoutPaymentMethods>,
      default: (): ICheckoutPaymentMethods => STUB_CHECKOUT_PAYMENT_METHODS,
    },
    selectedPaymentMethod: {
      type: Object as PropType<ICheckoutPaymentMethod>,
      default: null,
    },
  },
  methods: {
    select(selectedPaymentMethod: ICheckoutPaymentMethod): void {
      this.$emit('select', selectedPaymentMethod);
    },
  },
};
