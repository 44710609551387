


























import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseInputWithIcon from '../../atoms/BaseInputWithIcon/BaseInputWithIcon.vue';
import { ICheckoutOrderCouponInput } from './types';

export default {
  name: 'BaseCheckoutOrderCoupon',
  components: {
    BaseButton,
    BaseInputWithIcon,
  },
  props: {
    errorMessage: {
      type: String,
      default: 'Enter a code.',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data(): ICheckoutOrderCouponInput {
    return {
      value: '',
      showErrorMessage: this.hasError,
    };
  },
  watch: {
    hasError(): void {
      this.showErrorMessage = this.hasError;
    },
  },
  methods: {
    onInput(inputValue: string): void {
      if (inputValue) {
        this.showErrorMessage = false;
      }
      this.value = inputValue;
    },
    cancel(): void {
      this.showErrorMessage = false;
      this.$emit('cancel');
    },
    submit(): void {
      if (!this.value) {
        this.showErrorMessage = true;
      } else {
        this.showErrorMessage = false;
        this.$emit('submit', this.value);
      }
    },
  },
};
