















import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseInputWithIcon from '../../atoms/BaseInputWithIcon/BaseInputWithIcon.vue';
import { IAccountEmail } from './types';

export default {
  name: 'BaseCheckoutAccountEmail',
  components: {
    BaseButton,
    BaseInputWithIcon,
  },
  props: {
    initialEmailValue: {
      type: String,
      default: '',
    },
  },
  data(): IAccountEmail {
    return {
      email: this.initialEmailValue,
    };
  },
  methods: {
    onEmailInput(value: string): void {
      this.email = value;
    },
    submit(): void {
      this.$emit('email-submit', this.email);
    },
  },
};
