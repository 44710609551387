import { ICheckoutShippingMethods } from './types';

export const STUB_SHIPPING_METHODS: ICheckoutShippingMethods = [
  {
    name: 'standard',
    numberOfBusinessDays: 5,
    cost: 0.0,
  },
  {
    name: 'express',
    numberOfBusinessDays: 2,
    cost: 14.5,
  },
];
