






























































































import { PropType } from 'vue';
import { STUB_PRODUCT_LISTING_DATA, DEFAULT_THUMBNAIL } from './constants';
import { IProductSummary, IDefaultThumbnail, IProductAddedToCart } from './types';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseLink from '../../atoms/BaseLink/BaseLink.vue';
import BaseImage from '../../atoms/BaseImage/BaseImage.vue';
import BaseMaterialIcon from '../../atoms/BaseMaterialIcon/BaseMaterialIcon.vue';
import BaseAddedToCartPopup from '../../molecules/BaseAddedToCartPopup/BaseAddedToCartPopup.vue';

export default {
  name: 'BaseProductListingGrid',
  components: {
    BaseButton,
    BaseLink,
    BaseMaterialIcon,
    BaseImage,
    BaseAddedToCartPopup,
  },
  props: {
    products: {
      type: Array as PropType<Array<IProductSummary>>,
      default: (): IProductSummary[] => STUB_PRODUCT_LISTING_DATA,
    },
    cartLink: {
      type: String,
      default: '',
    },
    checkoutLink: {
      type: String,
      default: '',
    },
  },
  data(): IDefaultThumbnail & IProductAddedToCart {
    return {
      addedToCartPopupOpened: false,
      defaultThumbnail: DEFAULT_THUMBNAIL,
      selectedProduct: null,
    };
  },
  methods: {
    addProductToCart(selectedProduct: IProductSummary): void {
      this.selectedProduct = selectedProduct;
      this.openAddedToCartPopup();
      this.$emit('product-added-to-cart', selectedProduct);
    },
    openAddedToCartPopup(): void {
      this.addedToCartPopupOpened = true;
    },
    closeAddedToCartPopup(): void {
      this.addedToCartPopupOpened = false;
    },
  },
};
