import {
  IDeliveryDateQuoteResponse,
  IEstimateQuoteResponse,
} from '@api-client/src/api/getQuote/types';
import { IGetQuoteParams, IGetQuoteResponse } from '@composables/src/types';
import { Context, Logger } from '@vue-storefront/core';

import { IUseQuoteRefs } from '../types';
import { GET_QUOTE_GENERIC_ERROR_MESSAGE } from './constants';

function isEstimateQuoteResponse(
  quote: IEstimateQuoteResponse | IDeliveryDateQuoteResponse,
): quote is IEstimateQuoteResponse {
  return (quote as IEstimateQuoteResponse).estimated_transit_time !== undefined;
}

function isDeliveryDateQuoteResponse(
  quote: IEstimateQuoteResponse | IDeliveryDateQuoteResponse,
): quote is IDeliveryDateQuoteResponse {
  return (quote as IDeliveryDateQuoteResponse).delivery_date !== undefined;
}

const getEstimatedTransitTime = (quote: IEstimateQuoteResponse | IDeliveryDateQuoteResponse) => {
  const DEFAULT_MESSAGE =
    'Unable to provide estimate, please refer to shipping information in checkout';
  const todayDate = new Date().toISOString().split('T')[0];
  return isEstimateQuoteResponse(quote)
    ? quote.estimated_transit_time
    : isDeliveryDateQuoteResponse(quote)
    ? quote.delivery_date === todayDate
      ? 'Same day delivery'
      : DEFAULT_MESSAGE
    : DEFAULT_MESSAGE;
};

const getQuote =
  (context: Context, refs: IUseQuoteRefs, id: string) => async (quote: IGetQuoteParams) => {
    Logger.debug(`useQuote/${id}/getQuote`);

    try {
      refs.result.value = null;
      refs.error.value = null;
      refs.loading.value = true;

      const quoteResponse: IGetQuoteResponse = await context.$shippit.api.getQuote(quote);

      if (quoteResponse.response && quoteResponse.response.length) {
        const successfulQuote = quoteResponse.response.find((quote) => quote.success);

        if (successfulQuote) {
          const firstQuote = successfulQuote.quotes[0];
          refs.result.value = {
            estimatedTransitTime: getEstimatedTransitTime(firstQuote),
            postcode: quote.postcode,
            suburb: quote.suburb,
            price: firstQuote.price,
          };
        } else {
          refs.error.value = new Error(GET_QUOTE_GENERIC_ERROR_MESSAGE);
          Logger.error(`useQuote/${id}/getQuote/error`, quoteResponse);
        }
      } else {
        refs.error.value = new Error(GET_QUOTE_GENERIC_ERROR_MESSAGE);
        Logger.error(`useQuote/${id}/getQuote/error`, quoteResponse);
      }
    } catch (error) {
      refs.error.value = error as Error;
      Logger.error(`useQuote/${id}/getQuote/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default getQuote;
