/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Image,
  ImageConnection,
  Product,
  ProductConnection,
  ProductVariant,
  ProductVariantConnection,
} from '@apollo/src/types';

export type LocalProduct = Omit<Product, 'variants' | 'images'> & {
  variants: ProductVariant[] | ProductVariantConnection;
  images: Image[] | ImageConnection;
};

export function convertProductsGqlToLocal(data: ProductConnection): LocalProduct[] {
  return data.edges.map((edge: any) => ({
    ...edge.node,
    images: edge.node.images.edges.map((imageEdge: any) => imageEdge.node),
    variants: edge.node.variants.edges.map((variantEdge: any) => variantEdge.node),
  }));
}
