import { Collection, ISearchParams } from '@api-client/src/api/search/types';

const getFitmentByKtypeParams = (ktypnr: string): ISearchParams => {
  return {
    collection: Collection.FITMENT,
    resultsPerPage: 0,
    pipeline: {},
    query: ktypnr,
    filter: `ktypnr=${ktypnr}`,
    count: 'asku',
  };
};

export default getFitmentByKtypeParams;
