









import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseMaterialIcon from '../../atoms/BaseMaterialIcon/BaseMaterialIcon.vue';

export default {
  name: 'BaseHeaderMenuButton',
  components: {
    BaseButton,
    BaseMaterialIcon,
  },
  methods: {
    openMenu(): void {
      this.$emit('open-menu');
    },
  },
};
