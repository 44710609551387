














































import { PropType } from 'vue';
import BaseCheckoutOrderCoupon from '../../molecules/BaseCheckoutOrderCoupon/BaseCheckoutOrderCoupon.vue';
import { STUB_CHECKOUT_ORDER_ITEMS } from './constants';
import { ICheckoutOrderItems } from './types';

export default {
  name: 'BaseCheckoutOrderSummary',
  components: {
    BaseCheckoutOrderCoupon,
  },
  props: {
    items: {
      type: Array as PropType<ICheckoutOrderItems>,
      default: (): ICheckoutOrderItems => STUB_CHECKOUT_ORDER_ITEMS,
    },
    subtotal: {
      type: Number,
      default: 323.49,
    },
    hasDiscount: {
      type: Boolean,
      default: false,
    },
    discountTotal: {
      type: Number,
      default: 0,
    },
    deliveryTimeText: {
      type: String,
      default: 'Arrives in 5 Business Days',
    },
    isDeliveryCalculated: {
      type: Boolean,
      default: true,
    },
    deliveryTotal: {
      type: Number,
      default: 0,
    },
    totalQuantity: {
      type: Number,
      default: 11,
    },
    total: {
      type: Number,
      default: 323.49,
    },
  },
  methods: {
    onCouponCancel(value: string): void {
      this.$emit('coupon-cancel', value);
    },
    onCouponSubmit(value: string): void {
      this.$emit('coupon-submit', value);
    },
  },
};
