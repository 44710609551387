import getAndEnhanceProducts from '@composables/src/helpers/internals/getAndEnhanceProducts';
import { Context, Logger } from '@vue-storefront/core';

import { ComponentReference } from './types';
import { isFulfilled } from './types';

export default async function concurrentResolveProductsByQuery(
  context: Context,
  shopProdByQueryRefs: ComponentReference[],
) {
  // iterate over the matches and find the data. if no data set null
  const shopProdByQueryRefsWithDataPromises = await Promise.allSettled(
    shopProdByQueryRefs.map(async (reference) => {
      const data = await searchShopifyProducts(
        context,
        reference.component?.query,
        parseInt(reference.component?.product_count),
        reference.component?.sort_key,
      );

      if (!data) {
        Logger.warn(
          `concurrentResolveProductsByQuery() Couldnt resolve data for StoryBlok 'ShopifyProducts' component: \n Possibly bad query`,
        );
      }

      return {
        ...reference,
        data,
      };
    }),
  );

  // filter out rejected promises
  const shopProdByQueryRefsWithData = shopProdByQueryRefsWithDataPromises
    .filter((promise) => isFulfilled(promise))
    .map(
      (fulfilledPromise) => (fulfilledPromise as PromiseFulfilledResult<ComponentReference>).value,
    );

  const shopProdByQueryData = shopProdByQueryRefsWithData.filter((ref) => ref.data);

  return shopProdByQueryData;
}

async function searchShopifyProducts(
  context: Context,
  query = '',
  first: number,
  sortKey = 'RELEVANCE',
) {
  const response = await context.$shopify.api.searchProducts({
    query,
    first,
    sortKey,
  });
  return getAndEnhanceProducts(response?.data?.products);
}
