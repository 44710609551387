













































import BaseIcon from '../BaseIcon/BaseIcon.vue';
import { IInputTextWithIconStates } from './types';

const hasInputValue = (value: string): boolean => {
  return value && value.trim().length > 0;
};

export default {
  name: 'BaseInputWithIcon',
  components: {
    BaseIcon,
  },
  props: {
    name: {
      type: String,
      default: 'field',
    },
    label: {
      type: String,
      default: 'Field',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    initialValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    footnote: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isSuccessful: {
      type: Boolean,
      default: false,
    },
    shouldDisplayError: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: 'Invalid',
    },
    shouldDisplaySuccess: {
      type: Boolean,
      default: true,
    },
    errorIcon: {
      type: String,
      default: 'error',
    },
    successIcon: {
      type: String,
      default: 'check',
    },
  },
  data(): IInputTextWithIconStates {
    return {
      value: this.initialValue,
      showError: this.shouldDisplayError && this.hasError,
      showSuccess: this.shouldDisplaySuccess && this.isSuccessful,
    };
  },
  watch: {
    initialValue(): void {
      this.value = this.initialValue;
    },
    hasError(): void {
      this.showError = this.shouldDisplayError && this.hasError;
    },
    isSuccessful(): void {
      this.showSuccess = this.shouldDisplaySuccess && this.isSuccessful;
    },
  },
  methods: {
    onInput(): void {
      this.$emit('input', this.value);
    },
    onFocus(): void {
      this.showError = false;
      this.showSuccess = false;

      this.$emit('focus');
    },
    onBlur(): void {
      const hasValue = hasInputValue(this.value);
      const hasNoRequiredValue = this.required && this.shouldDisplayError && !hasValue;

      this.showError = this.hasError || hasNoRequiredValue;
      this.showSuccess = this.shouldDisplaySuccess && !hasNoRequiredValue && hasValue;

      this.$emit('blur');
    },
  },
};
