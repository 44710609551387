import { ProductsSearchParams, UseSearchFactoryParams } from '@vue-storefront/core';

import type { ProductsSearchResult } from './';

export const useSearchFactoryParams: UseSearchFactoryParams<
  ProductsSearchResult,
  ProductsSearchParams
> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async search(context: any, params: ProductsSearchParams): Promise<ProductsSearchResult> {
    const response = await context.$shopify.api.searchProduct(params);

    return response;
  },
};
