
















import clickOutside from 'vue-click-outside';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import { IData } from './types';

export default {
  name: 'BasePopover',
  directives: {
    clickOutside,
  },
  components: {
    BaseButton,
  },
  props: {
    label: {
      type: String,
      default: 'Name',
    },
  },
  data(): IData {
    return {
      opened: false,
    };
  },
  methods: {
    togglePopover(): void {
      this.opened = !this.opened;
    },
    close(): void {
      this.opened = false;
    },
  },
};
