

















































import {
  IAddressSearchResultItem,
  IAddressSearchConfig,
  IAddressSearchSelected,
  IAddressSearchStates,
  IAddressSearchResults,
} from './types';
import { STUB_ADDRESS_SEARCH_RESULTS } from './constants';
import BaseInputWithIcon from '../../atoms/BaseInputWithIcon/BaseInputWithIcon.vue';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseIcon from '../../atoms/BaseIcon/BaseIcon.vue';

export default {
  name: 'BaseAddressSearch',
  components: {
    BaseButton,
    BaseIcon,
    BaseInputWithIcon,
  },
  props: {
    userAddress: {
      type: Object,
      default: () => null,
    },
    searchResults: {
      type: Array,
      default: (): IAddressSearchResults => STUB_ADDRESS_SEARCH_RESULTS,
    },
    searchDebounceMs: {
      type: Number,
      default: 800,
    },
  },
  data(): IAddressSearchSelected & IAddressSearchStates & IAddressSearchConfig {
    return {
      selectedAddress: this.userAddress || null,
      searchValue: '',
      searchTimeout: 0,
      showSearchResults: false,
      isSearching: false,
    };
  },
  methods: {
    onSearchInput(searchValue: string): void {
      this.searchValue = searchValue;

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.isSearching = true;
      this.toggleSearchResultsDisplay(true);

      if (!searchValue) {
        this.toggleSearchResultsDisplay(false);
        return;
      }

      this.searchTimeout = setTimeout(() => {
        this.isSearching = false;

        this.$emit('search', searchValue);
      }, this.searchDebounceMs);
    },
    onSearchFocus(): void {
      if (this.searchValue) {
        this.toggleSearchResultsDisplay(true);
      }
    },
    clearSelectedAddress(): void {
      this.selectedAddress = null;
      this.searchValue = '';
      this.$emit('clear');
    },
    selectAddress(selectedAddress: IAddressSearchResultItem): void {
      this.selectedAddress = selectedAddress;
      this.toggleSearchResultsDisplay(false);
      this.$emit('select', selectedAddress);
    },
    toggleSearchResultsDisplay(shouldShow: boolean): void {
      this.showSearchResults = shouldShow;
    },
  },
};
