import createUserAddress from '@composables/src/useUserAddresses/createUserAddress';
import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import { Context } from '../types';
import loadUserAddresses from './loadUserAddresses';
import removeUserAddress from './removeUserAddress';
import { IUseUserAddressesError, IUseUserAddressesLoading, IUseUserAddressesResult } from './types';
import updateDefaultUserAddress from './updateDefaultUserAddress';
import updateUserAddress from './updateUserAddress';

const useUserAddresses = () => {
  const context = useVSFContext() as Context;

  const loading = sharedRef(
    {
      loadUserAddresses: false,
      updateUserAddress: false,
      createUserAddress: false,
      removeUserAddress: false,
      updateDefaultUserAddress: false,
    },
    'useUserAddresses/loading',
  );

  const result = sharedRef(
    {
      loadUserAddresses: null,
      updateUserAddress: null,
      createUserAddress: null,
      removeUserAddress: null,
      updateDefaultUserAddress: null,
    },
    'useUserAddresses',
  );

  const error = sharedRef(
    {
      loadUserAddresses: null,
      updateUserAddress: null,
      createUserAddress: null,
      removeUserAddress: null,
      updateDefaultUserAddress: null,
    },
    'useUserAddresses/error',
  );

  const refs = {
    result,
    loading,
    error,
  };

  return {
    loadUserAddresses: loadUserAddresses(context, refs),
    updateUserAddress: updateUserAddress(context, refs),
    createUserAddress: createUserAddress(context, refs),
    removeUserAddress: removeUserAddress(context, refs),
    updateDefaultUserAddress: updateDefaultUserAddress(context, refs),
    result: computed<IUseUserAddressesResult>(() => result.value),
    loading: computed<IUseUserAddressesLoading>(() => loading.value),
    error: computed<IUseUserAddressesError>(() => error.value),
  };
};

export default useUserAddresses;
