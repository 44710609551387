import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import resolveNestedRelations from './resolveNestedRelations';
import { UseNestedRelationsError, UseNestedRelationsLoading } from './types';

const useNestedRelations = () => {
  const context = useVSFContext();

  const loading = sharedRef(false, 'useNestedRelations/loading');

  const error = sharedRef(null, 'useNestedRelations/error');

  const refs = {
    loading,
    error,
  };

  return {
    resolveNestedRelations: resolveNestedRelations(context, refs),
    loading: computed<UseNestedRelationsLoading>(() => loading.value),
    error: computed<UseNestedRelationsError>(() => error.value),
  };
};

export default useNestedRelations;
