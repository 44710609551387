import { IAddressType, IGetPlaceDetailsResponse } from '@api-client/src/api/getPlaceDetails/types';

export interface ILocation {
  postcode: string;
  state: string;
  suburb: string;
}

const getLocation = (placeDetails: IGetPlaceDetailsResponse): ILocation => {
  const location: ILocation = {
    postcode: '',
    state: '',
    suburb: '',
  };

  placeDetails.result?.address_components?.forEach((addressComponent) => {
    if (addressComponent.types.includes(IAddressType.LOCALITY)) {
      location.suburb = addressComponent.short_name;
    } else if (addressComponent.types.includes(IAddressType.POSTAL_CODE)) {
      location.postcode = addressComponent.short_name;
    } else if (addressComponent.types.includes(IAddressType.ADMINISTRATIVE_AREA_LEVEL_1)) {
      location.state = addressComponent.short_name;
    }
  });

  return location;
};

const placeDetailsGetters = {
  getLocation,
};

export default placeDetailsGetters;
