export interface IArbitraryNestedObject {
  [key: string | number]: number | string | IArbitraryNestedObject | unknown | Array<any>;
}

export interface IKeySearchValues {
  [key: string | number]: Array<string | number>;
}

export type TreeSearchMatch = {
  key: string | number;
  value: string | number;
  index: string;
};

export function getByString(obj: IArbitraryNestedObject, path: string, backSteps = 0): any {
  const keys = path.split('.');
  keys.splice(keys.length - backSteps, backSteps);
  return keys.reduce((acc, curr) => {
    if (typeof acc === 'object' && typeof acc[curr] != undefined) {
      return acc[curr] as IArbitraryNestedObject;
    } else return acc;
  }, obj);
}

export function setByString(obj: IArbitraryNestedObject, path: string, data: any, backSteps = 0) {
  const keys = path.split('.');
  keys.splice(keys.length - backSteps, backSteps);
  keys.reduce((accumulator, key, idx) => {
    return idx + 1 === keys.length ? (accumulator[key] = data) : accumulator[key];
  }, obj);
}

// returns object containing indexpaths to keyValue pair matches
export function getMatches(
  object: IArbitraryNestedObject,
  keyValues: IKeySearchValues,
  path = '',
): TreeSearchMatch[] {
  const results = [];
  for (const key in object) {
    if (typeof object[key] === 'object') {
      results.push(
        ...getMatches(
          object[key] as IArbitraryNestedObject,
          keyValues,
          path + (path ? '.' : '') + key,
        ),
      );
    } else if (checkMatch(keyValues, key, object[key])) {
      results.push({
        key: key,
        value: object[key] as string | number,
        index: `${path}.${key}`,
      });
    }
  }
  return results;
}

// checks if current key and value are a match to anything in keyValues searchparams
export function checkMatch(keyValues: IKeySearchValues, key: string | number, value: any) {
  for (const checkKey in keyValues) {
    if (checkKey === key && keyValues[checkKey].includes(value)) {
      return true;
    }
  }
  return false;
}

export function getPathUpNNodes(path: string, backSteps: number) {
  const pathKeys = path.split('.');
  pathKeys.splice(pathKeys.length - backSteps, backSteps);
  return pathKeys.join('.');
}

export function isNodeAnArray(story: IArbitraryNestedObject, path: string) {
  return Array.isArray(getByString(story, path));
}
