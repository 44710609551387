































































import { PropType } from 'vue';
import BaseLink from '../../atoms/BaseLink/BaseLink.vue';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseImage from '../../atoms/BaseImage/BaseImage.vue';
import BaseIcon from '../../atoms/BaseIcon/BaseIcon.vue';
import {
  ISearchResultCategoryList,
  ISearchResultProductList,
  ISearchResultVehicleList,
} from './types';
import {
  STUB_SEARCH_RESULTS_CATEGORIES,
  STUB_SEARCH_RESULTS_PRODUCTS,
  STUB_SEARCH_RESULTS_VEHICLES,
} from './constants';

const doResultsExist = (
  results: ISearchResultCategoryList | ISearchResultProductList | ISearchResultVehicleList,
): boolean => {
  return results && results.length > 0;
};

export default {
  name: 'BaseHeaderSearchResults',
  components: {
    BaseButton,
    BaseIcon,
    BaseImage,
    BaseLink,
  },
  props: {
    categories: {
      type: Array as PropType<ISearchResultCategoryList>,
      default: (): ISearchResultCategoryList => STUB_SEARCH_RESULTS_CATEGORIES,
    },
    products: {
      type: Array as PropType<ISearchResultProductList>,
      default: (): ISearchResultProductList => STUB_SEARCH_RESULTS_PRODUCTS,
    },
    vehicles: {
      type: Array as PropType<ISearchResultVehicleList>,
      default: (): ISearchResultVehicleList => STUB_SEARCH_RESULTS_VEHICLES,
    },
    allProductsResultsLink: {
      type: String,
      default: '#',
    },
    noResultsContactLink: {
      type: String,
      default: '#',
    },
  },
  computed: {
    categoriesExist(): boolean {
      return doResultsExist(this.categories);
    },
    productsExist(): boolean {
      return doResultsExist(this.products);
    },
    vehiclesExist(): boolean {
      return doResultsExist(this.vehicles);
    },
  },
  methods: {
    close(): void {
      this.$emit('close');
    },
  },
};
