/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */
import { Cart, CartItem } from '@api-client/src/types';
import { Product } from '@apollo/src/types';
import { Context } from '@vue-storefront/core';

import logThrottledRequests from '../helpers/logThrottledRequests';
import { useCartFactory } from './useCartFactory';

// Expiry of cookie to 9 days and 23 hours as shopify cart cookies expire in 10 days.
const CART_COOKIE_EXPIRY = 60 * 60 * 24 * 8;

const CHECKOUT_ID = '_cart_id';

// Create new cart ID
const createNewCheckout = async (context: Context): Promise<string> => {
  const checkoutCreateResponse = await context.$shopify.api.checkoutCreate();

  return checkoutCreateResponse.data?.checkoutCreate?.checkout?.id;
};
type Checkout = Cart;

const loadExistingCheckout = async (
  context: Context,
  existingCheckoutId: string,
): Promise<Checkout> => {
  const checkout = await context.$shopify.api.checkOut(existingCheckoutId);

  return checkout;
};

const params = {
  load: async (context: Context) => {
    const checkoutIdCookieName = `${context.$shopify.config.app.$config.appIdentifier}${CHECKOUT_ID}`;
    const existingCheckoutId = context.$shopify.config.app.$cookies.get(checkoutIdCookieName);
    let checkout: Checkout = {} as Checkout;

    if (existingCheckoutId) {
      checkout = await loadExistingCheckout(context, existingCheckoutId);
    }

    if (!checkout?.id || !!checkout?.order) {
      const checkoutId = await createNewCheckout(context);
      context.$shopify.config.app.$cookies.set(checkoutIdCookieName, checkoutId, {
        maxAge: CART_COOKIE_EXPIRY,
        path: '/',
      });
      checkout = await loadExistingCheckout(context, checkoutId);
    }

    logThrottledRequests('checkoutCart', params, checkout, {
      shouldLogEmptyResponse: false,
    });

    return checkout;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addItem: async (context: Context, { currentCart, product, quantity, customQuery }: any) => {
    const productVariantIds = {
      variantBySelectedOptions: product.variantBySelectedOptions,
      variantId: product.variantId,
    };

    const updatedCart = await context.$shopify.api
      .addToCart({
        currentCart,
        product: productVariantIds,
        quantity,
        customQuery,
      })
      .catch((e: any) => {
        throw new Error(e);
      });

    if (!updatedCart) {
      throw new Error(`Sorry, we could not add this item to your cart.`);
    }

    logThrottledRequests('addItemToCart', params, updatedCart);

    return updatedCart;
  },

  removeItem: async (context: Context, { currentCart, product }: any) => {
    const updatedCart = await context.$shopify.api
      .removeFromCart({ currentCart, product })
      .catch((e: any) => {
        throw new Error(e);
      });

    if (!updatedCart) {
      throw new Error('Sorry, we could not remove this item from your cart.');
    }

    logThrottledRequests('removeItemFromCart', params, updatedCart, {
      shouldLogEmptyResponse: false,
    });

    return updatedCart;
  },

  updateItemQty: async (context: Context, { currentCart, product, quantity }: any) => {
    const updatedCart = await context.$shopify.api
      .updateCart({ currentCart, product, quantity: Math.trunc(quantity) })
      .catch((e: any) => {
        throw new Error(e);
      });

    if (!updatedCart) {
      throw new Error('Sorry, we could not update the quantity of this item in your cart.');
    }

    logThrottledRequests('updateItemQuantityInCart', params, updatedCart);

    return updatedCart;
  },

  isInCart: (_context: Context, { currentCart, product }: any) => {
    const getBasketItemByProduct = ({ currentCart, product }: any) => {
      if (product) {
        let variantId: any;
        if (
          product &&
          product.variantBySelectedOptions &&
          product.variantBySelectedOptions !== null
        ) {
          variantId = product.variantBySelectedOptions.id;
        }
        if (product.variants) {
          variantId = product.variants[0].id;
        }
        if (product.barcodes) {
          // handle & convert plain product Id from BCapp to base64
          const variationIDPlain = `gid://shopify/ProductVariant/${variantId}`;
          const buff = Buffer.from(variationIDPlain);
          variantId = buff.toString('base64');
        }
        return currentCart?.lineItems?.find?.((item: any) => item.variant.id === variantId);
      }
      return false;
    };

    return Boolean(currentCart && getBasketItemByProduct({ currentCart, product }));
  },
};

const useCart = useCartFactory<Cart, CartItem, Product>(params as any);

export default useCart;
