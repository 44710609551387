import { IProductResult } from '@api-client/src/api/search/types';

export const getMetafield = (
  product: IProductResult,
  metafield: string,
): string | string[] | undefined => {
  return product?.record?.[metafield] ?? undefined;
};

const getWebPImageURL = (imageURL: string): string => {
  return `${imageURL}.webp`;
};

const getBase64ShopifyObjectId = (shopifyObjectName: string, shopifyObjectId: string): string => {
  const buff = Buffer.from(`gid://shopify/${shopifyObjectName}/${shopifyObjectId}`, 'utf-8');
  const base64 = buff.toString('base64');
  return base64;
};

export const getId = (product: IProductResult): string | undefined => {
  return product?.record?.id;
};

export const getName = (product: IProductResult): string | undefined => product?.record?.title;

const getImageUrl = (product: IProductResult): string | undefined => {
  return product?.record?.image_url || product?.record?.image_urls[0];
};

export const getCoverImage = (product: IProductResult, size = 'normal') => {
  let imgResolution = '600x600';
  if (size === 'medium') {
    imgResolution = '295x295';
  } else if (size === 'small') {
    imgResolution = '80x80';
  }

  const placeholderImage = `https://cdn.shopify.com/s/files/1/0407/1902/4288/files/placeholder_${imgResolution}.jpg?v=1625742127`;

  const imgSrc = getImageUrl(product);

  if (imgSrc) {
    const imgPath = imgSrc.substring(0, imgSrc.lastIndexOf('.'));
    const imgext = imgSrc.split('.').pop();
    const resizedImg = getWebPImageURL(`${imgPath}_${imgResolution}.${imgext}`);
    return resizedImg;
  }

  return getWebPImageURL(placeholderImage);
};

// Uses the first varian price to display
export const getPrice = (product: IProductResult): number | null => {
  const price = product?.record?.variant_prices[0];

  if (price) {
    const priceFloat = parseFloat(price);
    if (priceFloat === 0) {
      return null;
    }
    return priceFloat;
  }

  return null;
};

export const hasPrice = (product: IProductResult): boolean => {
  return !!product?.record?.variant_prices[0];
};

/*
 * Uses the corresponding (first) compare to price to the variant price
 * returned from getPrice()
 */
export const getCompareToPrice = (product: IProductResult): number | null => {
  const compareToPrice = product?.record?.variant_compare_at_prices[0];

  if (compareToPrice) {
    const compareToPriceFloat = parseFloat(compareToPrice);
    if (compareToPriceFloat === 0) {
      return null;
    }
    return compareToPriceFloat;
  }

  return null;
};

export const hasCompareToPrice = (product: IProductResult): boolean => {
  return !!product?.record?.variant_compare_at_prices[0];
};

export const getSavingsLabel = (product: IProductResult): string => {
  const price = getPrice(product);
  const compareToPrice = getCompareToPrice(product);

  return hasCompareToPrice(product) &&
    price !== null &&
    compareToPrice !== null &&
    compareToPrice !== undefined &&
    compareToPrice > 0 &&
    compareToPrice > price
    ? `Save ${(100 - (price * 100) / compareToPrice).toFixed(0)}%`
    : '';
};

export const getTags = (product: IProductResult): string[] => {
  const tags = product?.record?.tags;

  if (!tags) {
    return [];
  }

  // SEARCH IO behavior where when no tags are defined on product, it returns this array  => [""]
  if (tags.length === 1 && tags[0] === '') {
    return [];
  }

  return tags;
};

export const getSlug = (product: IProductResult): string | undefined => product?.record?.handle;

export const getProductSKU = (product: IProductResult): string | undefined =>
  product?.record?.variant_skus?.[0];

export const getProductPartNumber = (product: IProductResult): string | undefined =>
  product?.record?.variant_metafield_plytix_part_number?.[0];

export const getProductVendor = (product: IProductResult): string | undefined =>
  product?.record?.vendor;

export const getBase64ProductId = (product: IProductResult): string => {
  if (product && product.record) {
    return getBase64ShopifyObjectId('Product', product.record?.id);
  }
  return '';
};

export const getBase64ProductVariantId = (product: IProductResult): string => {
  if (product?.record?.variant_ids?.length === 1) {
    return getBase64ShopifyObjectId('ProductVariant', product.record?.variant_ids[0]);
  }

  return '';
};

export const getQuantity = (product: IProductResult): number => {
  if (product?.record?.inventory_quantity) {
    return parseInt(product.record.inventory_quantity, 10);
  }

  return 0;
};

const isMadeToOrder = (product: IProductResult): boolean => {
  if (product?.record?.metafield_plytix_made_to_order) {
    return product.record.metafield_plytix_made_to_order === 'true';
  }
  return false;
};

const isFreeSale = (product: IProductResult): boolean =>
  Array.isArray(product?.record?.variant_metafield_plytix_available_for_sale) &&
  product.record.variant_metafield_plytix_available_for_sale.includes('true') &&
  parseInt(product.record.inventory_quantity, 10) <= 0;

export const getAvailability = (
  product: IProductResult,
  options: { supportFreeSale: boolean } = { supportFreeSale: false },
): 'in-stock' | 'out-of-stock' | 'made-to-order' => {
  const stock = getQuantity(product);
  const madeToOrderRequired = isMadeToOrder(product);
  const isProductFreeSale = options.supportFreeSale && isFreeSale(product);

  if (stock <= 0 && !isProductFreeSale) {
    return 'out-of-stock';
  }

  if (madeToOrderRequired) {
    return 'made-to-order';
  }

  return 'in-stock';
};

export const hasVariants = (product: IProductResult): boolean => {
  if (product?.record?.metafield_bundles_type) {
    return product?.record?.metafield_bundles_type === 'variant';
  }

  if (product?.record?.variant_ids) {
    return product.record.variant_ids.length > 1;
  }

  return false;
};

export const hasFitment = (product: IProductResult): boolean => {
  if (product?.record?.hasFitment === 'true') {
    return true;
  }

  return false;
};
