import { MailingAddressInput } from '@apollo/src/types';
import { Logger } from '@vue-storefront/core';

import getCustomerAccessToken from '../../helpers/internals/getCustomerAccessToken';
import { Context } from '../../types';
import { IUseUserAddressesRefs } from '../types';

const createUserAddress =
  (context: Context, refs: IUseUserAddressesRefs) => async (addressInput: MailingAddressInput) => {
    const customerAccessToken = getCustomerAccessToken(context);

    Logger.debug('useUserAddresses/createUserAddress');

    try {
      refs.loading.value.createUserAddress = true;

      const response = await context.$shopify.api.createAddress({
        customerAccessToken,
        address: addressInput,
      });

      const data = response.data?.customerAddressCreate;

      if (data && !data.customerUserErrors.length && !response.errors) {
        refs.result.value.createUserAddress = data;
      } else {
        const errors = data?.customerUserErrors || response.errors;

        if (errors) {
          refs.error.value.createUserAddress = errors;

          Logger.error('useUserAddresses/createUserAddress/error', errors);
        }
      }
    } catch (error) {
      refs.error.value.createUserAddress = error as Error;
      Logger.error('useUserAddresses/createUserAddress/error', error);
    } finally {
      refs.loading.value.createUserAddress = false;
    }
  };

export default createUserAddress;
