


















import { PropType } from 'vue';
import BaseImage from '../../atoms/BaseImage/BaseImage.vue';
import { STUB_PAYMENT_METHODS } from './constants';
import { IPaymentMethods } from './types';

export default {
  name: 'BasePaymentMethods',
  components: {
    BaseImage,
  },
  props: {
    paymentMethods: {
      type: Array as PropType<IPaymentMethods>,
      default: (): IPaymentMethods => STUB_PAYMENT_METHODS,
    },
    imageWidth: {
      type: String,
      default: 'auto',
    },
    imageHeight: {
      type: String,
      default: '30',
    },
  },
};
