import { IProductTypes } from './types';

export const STUB_PRODUCT_TYPES: IProductTypes = {
  all: {
    label: 'All Products',
    value: 'All',
  },
  clearance: {
    label: 'Clearance Products',
    value: 'Clearance',
  },
};
