import { Context, Logger } from '@vue-storefront/core';

import { ComponentReference } from './types';

export default async function batchResolveCategories(
  context: Context,
  shopCategoryRefs: ComponentReference[],
) {
  const titleFilter = shopCategoryRefs
    .map((ref) => `'${ref.component?.shopify_category?.items?.[0]?.name.replace("'", "\\'")}'`)
    .join(' OR ');

  const categoriesResponse = await context.$shopify.api.searchCollections({
    query: titleFilter,
  });

  const categories =
    categoriesResponse.data?.collections?.edges?.map((edge: { node: any }) => edge.node) ?? [];

  // iterate over the matches and find the data. if no data set null
  const shopCategoryRefsWithData = shopCategoryRefs.map((reference) => {
    const data = categories.find((cat: any) => {
      return cat.title === reference?.component?.shopify_category?.items?.[0]?.name;
    });

    if (!data) {
      Logger.warn(
        `batchResolveCategories() Couldnt resolve data for: ${reference?.component?.shopify_category?.items?.[0]?.name} :\n Possibly Stale Shopify reference`,
      );
    }

    return {
      ...reference,
      data,
    };
  });

  // filter out null results
  const shopCategoryData = shopCategoryRefsWithData.filter((ref) => ref.data);

  return shopCategoryData;
}
