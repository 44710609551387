import { ICheckoutPaymentMethods } from './types';

export const STUB_CHECKOUT_PAYMENT_METHODS: ICheckoutPaymentMethods = [
  {
    name: 'Credit Card',
    logos: [
      {
        alt: 'MasterCard',
        src: '/assets/storybook/BasePaymentMethods/mastercard.png',
      },
      {
        alt: 'VISA',
        src: '/assets/storybook/BasePaymentMethods/visa.png',
      },
      {
        alt: 'American Express',
        src: '/assets/storybook/BasePaymentMethods/amex.png',
      },
    ],
  },
  {
    name: 'PayPal',
    logos: [
      {
        alt: 'PayPal',
        src: '/assets/storybook/BasePaymentMethods/paypal.png',
      },
    ],
  },
  {
    name: 'Afterpay',
    logos: [
      {
        alt: 'Afterpay',
        src: '/assets/storybook/BasePaymentMethods/afterpay.svg',
      },
    ],
  },
  {
    name: 'Zip Money',
    logos: [
      {
        alt: 'Zip Money',
        src: '/assets/storybook/BasePaymentMethods/zippay.png',
      },
    ],
  },
];
