import { ISearchIOAggregation, ISearchResponse } from '../types';

const getFitmentAskus = (response: ISearchResponse): string[] => {
  const aSkus =
    (response?.aggregateFilters?.['count.asku'] as ISearchIOAggregation)?.count?.counts || {};

  return Object.keys(aSkus);
};

export default getFitmentAskus;
