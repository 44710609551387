




















import { PropType } from 'vue';
import isObject from 'lodash/isObject';
import { Option, IData } from './types';
import { STUB_OPTIONS_PROP } from './constants';

const getOptionProperty = (option: Option, key: string): string => {
  if (isObject(option)) {
    return option[key];
  }

  return option.toString();
};

export default {
  name: 'BaseNativeSelect',
  props: {
    options: {
      type: Array as PropType<Array<Option>>,
      default: (): Option[] => STUB_OPTIONS_PROP,
    },
    defaultOption: {
      type: String,
      default: (): Option => STUB_OPTIONS_PROP[0],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data(): IData {
    const defaultValue = getOptionProperty(this.defaultOption, 'value');

    return {
      selected: defaultValue || '',
    };
  },
  watch: {
    defaultOption(): void {
      this.selected = getOptionProperty(this.defaultOption, 'value');
    },
  },
  methods: {
    getOptionProperty,
    onSelect(): void {
      this.$emit('select', this.selected);
    },
  },
};
