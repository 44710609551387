import {
  getByString,
  getPathUpNNodes,
  IArbitraryNestedObject,
  isNodeAnArray,
  setByString,
} from '../../helpers/utils/treeSearch';

export default function replaceDataInStory(
  story: IArbitraryNestedObject,
  path: string,
  data: any,
  componentsToReplace: string[],
) {
  const pathToContainerNode = getPathUpNNodes(path, 2);
  /*
   * if we are trying to write over an array of items with an array of items, actually just push the items in
   * the original components are then filtered out
   */
  if (isNodeAnArray(story, pathToContainerNode) && Array.isArray(data)) {
    /*
     * because two nodes up the parent is an array, one node up should be an array index.
     * return type for getbystring is IArbitraryobject so im casting it to array
     */
    let containerNode = getByString(story, pathToContainerNode, 0);
    containerNode.push(...data);
    containerNode = containerNode.filter((item: any) => {
      return !componentsToReplace.includes(item?.component);
    });
    setByString(story, pathToContainerNode, containerNode, 0);
  } else {
    setByString(story, path, data, 2);
  }
}
