
















































import { PropType } from 'vue';
import kebabCase from 'lodash/kebabCase';
import BaseAccordionItem from '../../atoms/BaseAccordionItem/BaseAccordionItem.vue';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseIcon from '../../atoms/BaseIcon/BaseIcon.vue';
import BaseListItem from '../../atoms/BaseListItem/BaseListItem.vue';
import BaseAccordion from '../../organisms/BaseAccordion/BaseAccordion.vue';
import BaseList from '../../organisms/BaseList/BaseList.vue';
import { CategoryLevel, ICategory, IData, ISubCategory } from './types';
import { STUB_CATEGORY_FILTER_DATA, STUB_SUB_CATEGORIES_FILTER_DATA } from './constants';

export default {
  name: 'BaseCategoryFilter',
  components: {
    BaseAccordion,
    BaseAccordionItem,
    BaseList,
    BaseListItem,
    BaseIcon,
    BaseButton,
  },
  props: {
    categories: {
      type: Array as PropType<Array<ICategory>>,
      default: (): ICategory[] => STUB_CATEGORY_FILTER_DATA,
    },
    subCategories: {
      type: Array as PropType<Array<ISubCategory>>,
      default: (): ISubCategory[] => STUB_SUB_CATEGORIES_FILTER_DATA,
    },
    defaultTopLevelCategory: {
      type: String,
      default: '',
    },
    defaultSubLevelCategory: {
      type: String,
      default: '',
    },
  },
  data(): IData {
    return {
      selectedCategory: null,
      selectedCategorySubCategories: [],
      selectedSubCategory: null,
    };
  },
  watch: {
    selectedCategory: function (selectedCategory: ICategory): Promise<void> {
      if (!selectedCategory) {
        return;
      }

      this.selectedCategorySubCategories = [];
      const subCategories = this.subCategories.filter(
        (subCategory: ISubCategory) => subCategory.categoryId === selectedCategory.id,
      );

      this.selectedCategorySubCategories = subCategories;

      if (this.defaultSubLevelCategory) {
        this.selectedSubCategory = subCategories.find(
          (subCategory) => subCategory.label === this.defaultSubLevelCategory,
        );
      }
    },
  },
  mounted(): void {
    if (this.defaultTopLevelCategory) {
      this.selectedCategory = STUB_CATEGORY_FILTER_DATA.find(
        (category) => category.label === this.defaultTopLevelCategory,
      );
    }
  },
  methods: {
    toggleCategory(
      selectedCategory: ICategory | ISubCategory,
      categoryLevel: CategoryLevel,
      accordionClick?: () => void,
    ): void {
      accordionClick && accordionClick();

      const categoryDataKey = categoryLevel === 'TOP' ? 'selectedCategory' : 'selectedSubCategory';

      if (!this[categoryDataKey]) {
        this[categoryDataKey] = selectedCategory;
      } else if (this[categoryDataKey].id === selectedCategory.id) {
        this[categoryDataKey] = null;
      } else {
        this[categoryDataKey] = selectedCategory;
      }

      this.$emit(kebabCase(categoryDataKey), this[categoryDataKey]);
    },
  },
};
