import { computed, Ref, ref } from '@nuxtjs/composition-api';
import { UseCheckout } from '@vue-storefront/core';

import { ShippingMethod, User, UserAddress } from '../types';

const PAYMENT_METHODS_MOCK = [
  {
    label: 'Visa Debit',
    value: 'debit',
  },
  {
    label: 'MasterCard',
    value: 'mastercard',
  },
  {
    label: 'Visa Electron',
    value: 'electron',
  },
  {
    label: 'Cash on delivery',
    value: 'cash',
  },
  {
    label: 'Check',
    value: 'check',
  },
];

const paymentMethods: Ref<unknown[]> = ref(PAYMENT_METHODS_MOCK);
const shippingMethods: Ref<unknown[]> = ref([]);
const personalDetails: Ref<User | null> = ref(null);
const shippingDetails: Ref<UserAddress | null> = ref(null);
const billingDetails: Ref<UserAddress | null> = ref(null);
const chosenPaymentMethod: Ref<string> = ref('');
const chosenShippingMethod: Ref<ShippingMethod | null> = ref(null);
const placeOrder = async () => {
  return null;
};

// @todo CHECKOUT
const useCheckout: () => UseCheckout<
  unknown,
  unknown,
  unknown,
  unknown,
  unknown,
  unknown,
  unknown,
  unknown
> = () => {
  return {
    paymentMethods,
    shippingMethods,
    personalDetails,
    shippingDetails,
    billingDetails,
    chosenPaymentMethod,
    chosenShippingMethod,
    placeOrder,
    loading: computed(() => false),
  };
};

export default useCheckout;
