import { ICategoryPath } from '@composables/src';

const SEARCH_STRING = 'Brand Categories';
const REPLACE_STRING = 'Brands';
const LINK_SEARCH_STRING = 'brand-categories';
const LINK_REPLACE_STRING = 'brands';

export const updateBreadcrumbsForNoProductCategory = (
  basePathToRemove: string,
  paths: ICategoryPath[],
) => {
  const foundIndex = paths.findIndex((path) => path.text.includes(SEARCH_STRING));
  return foundIndex !== -1
    ? paths.slice(foundIndex).map((path) => ({
        ...path,
        text: path.text.replace(SEARCH_STRING, REPLACE_STRING),
        link: path.link
          .replace(LINK_SEARCH_STRING, LINK_REPLACE_STRING)
          .replace(basePathToRemove, ''),
      }))
    : paths;
};
