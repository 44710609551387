










export default {
  name: 'BaseMaterialIcon',
  props: {
    size: {
      type: String,
      default: '24px',
    },
    weight: {
      type: String,
      default: '400',
    },
    color: {
      type: String,
      default: '#000000',
    },
    hoverColor: {
      type: String,
      default: '#000000',
    },
    icon: {
      type: String,
      default: 'menu',
    },
    kind: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconCSSVariables() {
      return {
        '--material-icon-color': this.color,
        '--material-icon-weight': this.weight,
        '--material-icon-size': this.size,
        '--material-icon-hover-color': this.hoverColor || this.color,
      };
    },
  },
};
