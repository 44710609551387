import { Context, Logger } from '@vue-storefront/core';

import { getByString, getMatches, IArbitraryNestedObject } from '../../helpers/utils/treeSearch';
import { IUseStoryblokRefs } from '../types';
import batchResolveCategories from './batchResolveCategories';
import batchResolveProductsBySku from './batchResolveProductsBySku';
import concurrentResolveProductsByCategory from './concurrentResolveProductsByCategory';
import concurrentResolveProductsByQuery from './concurrentResolveProductsByQuery';
import replaceDataInStory from './replaceDataInStory';
import {
  ComponentReference,
  isCategoryComponent,
  isFulfilled,
  isProductComponent,
  isProductsComponent,
  isProductsFromCategoryComponent,
} from './types';

function stringParseClone(deepObject: any) {
  return JSON.parse(JSON.stringify(deepObject));
}

const resolveShopifyData =
  (context: Context, refs: IUseStoryblokRefs) =>
  async (story: IArbitraryNestedObject): Promise<IArbitraryNestedObject> => {
    Logger.debug('useStoryblok/resolveShopifyData');
    const unreactiveDataClone = stringParseClone(story);
    try {
      refs.loading.value = true;
      await resolveData(context, unreactiveDataClone);
      refs.error.value = null;
    } catch (error) {
      refs.error.value = error as Error;
      Logger.error('useStoryblok/resolveShopifyData/error', error);
      return story;
    } finally {
      refs.loading.value = false;
    }
    return unreactiveDataClone;
  };

async function resolveData(context: Context, story: IArbitraryNestedObject) {
  // get index matches for relevant components with key 'component' containing values below
  const componentKeys = [
    'ShopifyProduct',
    'ShopifyProductsFromCategory',
    'ShopifyCategory',
    'ShopifyProducts',
  ];
  const keyValuePairsToSearchFor = {
    component: componentKeys,
  };
  const referencesToComponents = getMatches(story, keyValuePairsToSearchFor);

  // Split into different sets
  const shopProdRefs: ComponentReference[] = [];
  const shopCatRefs: ComponentReference[] = [];
  const shopProdByCatRefs: ComponentReference[] = [];
  const shopProdsRefs: ComponentReference[] = [];

  referencesToComponents.forEach((ref) => {
    const component = getByString(story, ref.index, 1);
    const componentRef = {
      ...ref,
      component,
    };
    if (isProductComponent(component)) {
      shopProdRefs.push(componentRef);
    } else if (isCategoryComponent(component)) {
      shopCatRefs.push(componentRef);
    } else if (isProductsFromCategoryComponent(component)) {
      shopProdByCatRefs.push(componentRef);
    } else if (isProductsComponent(component)) {
      shopProdsRefs.push(componentRef);
    }
  });

  // run queries on each set concurrently
  const [
    shopProdsBySkuRefsWithData,
    shopCatsRefsWithData,
    shopProdsByCatRefsWithData,
    shopProdsByQueryRefsWithData,
  ] = await Promise.allSettled([
    batchResolveProductsBySku(context, shopProdRefs),
    batchResolveCategories(context, shopCatRefs),
    concurrentResolveProductsByCategory(context, shopProdByCatRefs),
    concurrentResolveProductsByQuery(context, shopProdsRefs),
  ]);

  // insert all the data
  if (isFulfilled(shopProdsBySkuRefsWithData)) {
    shopProdsBySkuRefsWithData.value.forEach((ref) => {
      replaceDataInStory(story, ref.index, ref.data, componentKeys);
    });
  } else {
    Logger.error(
      `Error resolving Shopify data for Batched ShopifyProductsBySku: \n ${shopProdsBySkuRefsWithData.reason}`,
    );
  }

  if (isFulfilled(shopCatsRefsWithData)) {
    shopCatsRefsWithData.value.forEach((ref) => {
      replaceDataInStory(story, ref.index, ref.data, componentKeys);
    });
  } else {
    Logger.error(
      `Error resolving Shopify data for batched ShopifyCategories: \n ${shopCatsRefsWithData.reason}`,
    );
  }

  if (isFulfilled(shopProdsByCatRefsWithData)) {
    shopProdsByCatRefsWithData.value.forEach((ref) => {
      replaceDataInStory(story, ref.index, ref.data, componentKeys);
    });
  } else {
    Logger.error(
      `Error resolving Shopify data for ShopifyProductsByCategory: \n ${shopProdsByCatRefsWithData.reason}`,
    );
  }

  if (isFulfilled(shopProdsByQueryRefsWithData)) {
    shopProdsByQueryRefsWithData.value.forEach((ref) => {
      replaceDataInStory(story, ref.index, ref.data, componentKeys);
    });
  } else {
    Logger.error(
      `Error resolving Shopify data for ShopifyProductsByQuery: \n ${shopProdsByQueryRefsWithData.reason}`,
    );
  }
  return;
}
export default resolveShopifyData;
