import getAccessToken from '../../shared/getAccessToken';
import { extractNestedComponents } from '@shared/helpers';
import StoryblokClient from 'storyblok-js-client';
import { errorMessage } from '@shared/helpers/constants';
import { ApiContext, ApiResponse, ContentSearchParams } from '@shared/types';
import { handleApiError } from '@unified-commerce/gpc-api-helper';
import { Logger } from '@vue-storefront/core';

const fetchContent = async (
  api: ApiContext<StoryblokClient>,
  url: string,
  params: any,
): Promise<any> => {
  api.client.accessToken = getAccessToken(api.config, params) || '';

  const { data, headers }: { data: ApiResponse; headers: any } = await api.client.get(url, params);

  const returnData = data.story
    ? extractNestedComponents(data.story)
    : extractNestedComponents({ content: data.stories } as any, true) || [];

  return { ...returnData, headers };
};

const COLLECTION_ITEMS_PER_PAGE = 25;

const fetchCollection = async (
  api: ApiContext<StoryblokClient>,
  url: string,
  params: any,
): Promise<any> => {
  const initial = await fetchContent(api, url, {
    ...params,
    per_page: 1,
    page: 1,
  });

  const totalPages = Math.ceil(initial.headers.total / COLLECTION_ITEMS_PER_PAGE);
  const requests: Promise<any>[] = [];

  for (let page = 1; page <= totalPages; page++) {
    requests.push(
      fetchContent(api, url, {
        ...params,
        per_page: COLLECTION_ITEMS_PER_PAGE,
        page,
      }),
    );
  }

  const responses = await Promise.all(requests);

  return responses.reduce(
    (finalResult, response, pageIndex) => {
      const { _meta, headers, ...content } = response;

      Object.keys(content).forEach((pageItemIndex) => {
        const collectionItemIndex = Number(pageItemIndex) + pageIndex * COLLECTION_ITEMS_PER_PAGE;
        finalResult[`${collectionItemIndex}`] = content[pageItemIndex];
      });

      finalResult._meta.push(_meta);
      finalResult.headers.push(headers);

      return finalResult;
    },
    {
      _meta: [],
      headers: [],
    },
  );
};

const getContent = async (
  api: ApiContext<StoryblokClient>,
  params: ContentSearchParams,
): Promise<any | void> => {
  const { id, url, custom, locale, relations, version = 'published', disablePagination } = params;

  if (!url && !id && !custom) {
    Logger.warn(`${errorMessage.GENERAL} ${errorMessage.EMPTY_ID}`);
    return;
  }

  if (!id && custom && typeof custom !== 'object') {
    Logger.warn(`${errorMessage.GENERAL} ${errorMessage.WRONG_CUSTOM}`);
    return;
  }

  const requestUrl = `cdn/stories/${id || custom ? '' : url}`;

  const requestParams = {
    ...(id ? { by_uuids_ordered: id } : custom || {}),
    resolve_relations: relations,
    language: locale,
    version,
  };

  try {
    return disablePagination
      ? await fetchCollection(api, requestUrl, requestParams)
      : await fetchContent(api, requestUrl, requestParams);
  } catch (error: unknown) {
    const { apiError, statusCode } = handleApiError(error);

    if (!(params.optimisticStoryLoading && statusCode === 404)) {
      Logger.error('gpc-vue-storefront-storyblok/api-client/getContent', error, params);
    }

    throw apiError;
  }
};

export default getContent;
