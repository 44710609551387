import { ISignupToNewsletterParams } from '@composables/src/types';
import { Context, Logger } from '@vue-storefront/core';

import { IUseNewsletterSignupRefs } from '../types';

const signupToNewsletter =
  (context: Context, refs: IUseNewsletterSignupRefs, id: string) =>
  async (userDetails: ISignupToNewsletterParams) => {
    Logger.debug(`useNewsletterSignup/${id}/signupToNewsletter`);

    try {
      refs.result.value = null;
      refs.error.value = null;
      refs.loading.value = true;

      const signedUpToNewsletterSuccessfully = await context.$shopifyAdmin.api.signupToNewsletter(
        userDetails,
      );

      if (!signedUpToNewsletterSuccessfully) {
        refs.error.value = new Error('We are sorry. We could not get you subscribed at this time.');
      } else {
        refs.result.value = true;
      }
    } catch (error) {
      refs.error.value = error as Error;
      Logger.error(`useNewsletterSignup/${id}/signupToNewsletter/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default signupToNewsletter;
