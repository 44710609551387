import { ISearchIOAggregation, ISearchResponse } from '../types';

const getVehicleSelectionBodyOptions = (response: ISearchResponse) => {
  const body =
    (response?.aggregateFilters?.['count.BodyType'] as ISearchIOAggregation)?.count?.counts || {};

  return Object.keys(body).map((key: string) => {
    return {
      title: key,
      value: key.toLowerCase(),
    };
  });
};

export default getVehicleSelectionBodyOptions;
