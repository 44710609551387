import { ICheckboxGroupItem } from '../../atoms/BaseCheckboxGroupItem/types';

export const STUB_CHECKBOX_LIST_PROP = <Array<ICheckboxGroupItem>>[
  {
    label: 'PAT',
    name: 'pat',
    value: 'pat',
    trailingNumber: 3290,
  },
  {
    label: 'Fuelmiser',
    name: 'fuelmiser',
    value: 'fuelmiser',
    trailingNumber: 2843,
  },
  {
    label: 'Goss',
    name: 'goss',
    value: 'goss',
    trailingNumber: 1892,
  },
  {
    label: 'Tridon',
    name: 'tridon',
    value: 'tridon',
    trailingNumber: 1490,
  },
  {
    label: 'Bosch Automotive',
    name: 'bosch-automotive',
    value: 'bosch-automotive',
    trailingNumber: 1439,
  },
  {
    label: 'NGK Spark Plugs',
    name: 'ngk-spark-plugs',
    value: 'ngk-spark-plugs',
    trailingNumber: 1275,
  },
  {
    label: 'OEX',
    name: 'oex',
    value: 'oex',
    trailingNumber: 979,
  },
  {
    label: 'Genuine OEM parts',
    name: 'genuine-oem-parts',
    value: 'genuine-oem-parts',
    trailingNumber: 759,
  },
  {
    label: 'Denso',
    name: 'denso',
    value: 'denso',
    trailingNumber: 222,
  },
  {
    label: 'VEMO',
    name: 'vemo',
    value: 'vemo',
    trailingNumber: 181,
  },
  {
    label: 'MAHLE',
    name: 'mahle',
    value: 'mahle',
    trailingNumber: 162,
  },
  {
    label: 'Valeo',
    name: 'valeo',
    value: 'valeo',
    trailingNumber: 114,
  },
  {
    label: 'Aeroflow',
    name: 'aeroflow',
    value: 'aeroflow',
    trailingNumber: 51,
  },
  {
    label: 'SWAG',
    name: 'swag',
    value: 'swag',
    trailingNumber: 46,
  },
  {
    label: 'Raceworks',
    name: 'raceworks',
    value: 'raceworks',
    trailingNumber: 34,
  },
  {
    label: 'Pierburg',
    name: 'pierburg',
    value: 'pierburg',
    trailingNumber: 31,
  },
  {
    label: 'VDO',
    name: 'vdo',
    value: 'vdo',
    trailingNumber: 19,
  },
  {
    label: 'MSD Ignition',
    name: 'msd-ignition',
    value: 'msd-ignition',
    trailingNumber: 17,
  },
  {
    label: 'Dorman',
    name: 'dorman',
    value: 'dorman',
    trailingNumber: 12,
  },
];
