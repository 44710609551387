import {
  ISearchResultCategoryList,
  ISearchResultProductList,
  ISearchResultVehicleList,
} from './types';

export const STUB_SEARCH_RESULTS_CATEGORIES: ISearchResultCategoryList = [
  {
    label: 'Parts > Brakes > Brake Pads',
    link: '/parts/brakes/brake-pads',
  },
  {
    label: 'Parts > Brakes > Brake Cylinders > Brake Master Cylinders',
    link: '/parts/brakes/brake-cylinders/brake-master-cylinders',
  },
  {
    label: 'Parts > Brakes > Brake Lines',
    link: '/parts/brakes/brake-lines',
  },
  {
    label: 'Parts > Brakes > Brake Cylinders > Brake Wheel Cylinders',
    link: '/parts/brakes/brake-pads',
  },
  {
    label: 'Parts > Brakes > Brake Sensors & ABS Modules > Brake Wear Sensors',
    link: '/parts/brakes/brake-sensors-abs-modules/brake-wear-sensors',
  },
];

export const STUB_SEARCH_RESULTS_PRODUCTS: ISearchResultProductList = [
  {
    thumbnailSrc: 'https://sb-images-product-small.sparesbox.com.au/rda-standard-pair-hero-new.jpg',
    name: 'RDA OE Replacement Brake Rotor Pair Rear RDA7016',
    link: '/part/rda-oe-replacment-brake-rotor-pair-rear-rda7016',
    price: 372.95,
  },
  {
    thumbnailSrc: 'https://sb-images-product-small.sparesbox.com.au/rda-dimpled-pair-hero-new.jpg',
    name: 'RDA Slotted And Dimpled Brake Rotor Pair RDA7016D',
    link: '/part/rda-slotted-and-dimpled-brake-rotor-pair-rda7016d',
    price: 469.95,
  },
  {
    thumbnailSrc:
      'https://sb-images-product-small.sparesbox.com.au/bendix-2021-bws1156-11.03.15-lr.jpg',
    name: 'Bendix Brake Pad Wear Sensor BWS1156',
    link: '/part/bendix-brake-pad-wear-sensor-bws1156',
    price: 2.51,
    originalPrice: 2.95,
    discountAmount: 0.44,
  },
  {
    thumbnailSrc: 'https://sb-images-product-small.sparesbox.com.au/dba-drum-pair-generic-1.jpg',
    name: 'DBA Brake Drum Pair DBA1717',
    link: '/part/bendix-brake-pad-wear-sensor-bws1156',
    price: 87.96,
    originalPrice: 109.95,
    discountAmount: 21.99,
  },
  {
    thumbnailSrc: 'https://sb-images-product-small.sparesbox.com.au/65-brembo-pad-1-1.jpg',
    name: 'Brembo Brake Pad Front Set (Low-Met) PA6026',
    link: '/part/brembo-brake-pad-front-set-low-met-pa6026-p-a6-026',
    price: 99.95,
  },
];

export const STUB_SEARCH_RESULTS_VEHICLES: ISearchResultVehicleList = [
  {
    name: 'Mazda Bravo B2000 (UD) Ute Petrol 1983 - 1984',
    link: '/vehicle/mazda-bravo-b2000-ud-ute-petrol-1983-1984',
  },
  {
    name: 'Subaru BRZ Coupe ZC6 Coupe Petrol 2012 - 2021',
    link: '/vehicle/subaru-brz-coupe-zc6-coupe-petrol-2012-2021',
  },
  {
    name: 'Ford Falcon 4.0 XR6 Turbo (BA) 220 kW Cab Chassis Petrol 2002 - 2005',
    link: '/vehicle/ford-falcon-4-0-xr6-turbo-ba-220-kw-cab-chassis-petrol-2002-2005',
  },
  {
    name: 'Daihatsu F77 Tray back SUV Diesel 1984 - 1993',
    link: '/vehicle/daihatsu-f77-tray-back-suv-diesel-1984-1993',
  },
  {
    name: 'Volvo P 121 2 Sedan Petrol 1968 - 1971',
    link: '/vehicle/volvo-p-121-2-sedan-petrol-1968-1971',
  },
];
