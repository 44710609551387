import { ApiContext } from '@shared/types';
import StoryblokClient from 'storyblok-js-client';
import { ApiResponse, handleApiError } from '@unified-commerce/gpc-api-helper';
import { Logger } from '@vue-storefront/core';

import { SbCommonOptions, SbCommonParams } from '../../shared/types';
import mapSbCommonParams from '../../shared/mapSbCommonParams';

export type SbLinkSearchParams = { id?: string } & SbCommonParams & SbCommonOptions;

export interface SbLink {
  id?: number;
  slug?: string;
  name?: string;
  is_folder?: boolean;
  parent_id?: number;
  published?: boolean;
  position?: number;
  uuid?: string;
  is_startpage?: boolean;
}

export interface SbLinks {
  links?: {
    [key: string]: SbLink;
  };
}

export type SbLinksResponse = ApiResponse<SbLinks>;

const getLinks = async (
  api: ApiContext<StoryblokClient>,
  params: SbLinkSearchParams = {},
): Promise<SbLinksResponse> => {
  const { id } = params;

  const storyblokParams = mapSbCommonParams(params);

  let url = 'cdn/links';

  if (id) {
    url += `/${id}`;
  }

  try {
    const { data } = await api.client.get(url, storyblokParams);

    return {
      statusCode: 200,
      data,
    };
  } catch (error) {
    const { apiError } = handleApiError(error);

    Logger.error('gpc-vue-storefront-storyblok/api-client/getLinks', error, storyblokParams);

    throw apiError;
  }
};

export default getLinks;
