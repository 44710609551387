





























































































































import { PropType } from 'vue';
import BaseInputWithIcon from '../../atoms/BaseInputWithIcon/BaseInputWithIcon.vue';
import BaseNativeSelect from '../../atoms/BaseNativeSelect/BaseNativeSelect.vue';
import BaseAddressSearch from '../../molecules/BaseAddressSearch/BaseAddressSearch.vue';
import BaseCheckoutShippingMethodsSelector from '../../molecules/BaseCheckoutShippingMethodsSelector/BaseCheckoutShippingMethodsSelector.vue';
import BaseCheckoutPaymentMethodsSelector from '../../molecules/BaseCheckoutPaymentMethodsSelector/BaseCheckoutPaymentMethodsSelector.vue';
import {
  ICheckoutPaymentMethod,
  ICheckoutPaymentMethods,
} from '../../molecules/BaseCheckoutPaymentMethodsSelector/types';
import {
  ICheckoutShippingMethod,
  ICheckoutShippingMethods,
} from '../../molecules/BaseCheckoutShippingMethodsSelector/types';
import { IAddressSearchResultItem } from '../../molecules/BaseAddressSearch/types';
import { ICheckoutFormFields } from './types';

export default {
  name: 'BaseCheckoutForm',
  components: {
    BaseInputWithIcon,
    BaseNativeSelect,
    BaseAddressSearch,
    BaseCheckoutShippingMethodsSelector,
    BaseCheckoutPaymentMethodsSelector,
  },
  props: {
    userEmail: {
      type: String,
      default: '',
    },
    userFirstName: {
      type: String,
      default: '',
    },
    userLastName: {
      type: String,
      default: '',
    },
    userBusinessName: {
      type: String,
      default: '',
    },
    userAddress: {
      type: Object as PropType<IAddressSearchResultItem>,
      default: null,
    },
    addressSearchResults: {
      type: Array,
      default: () => [],
    },
    addressSearchDebounceMs: {
      type: Number,
      default: 800,
    },
    userPhoneNumber: {
      type: String,
      default: '',
    },
    shippingMethodOptions: {
      type: Array as PropType<ICheckoutShippingMethods>,
      default: () => [],
    },
    selectedShippingMethod: {
      type: Object as PropType<ICheckoutShippingMethod>,
      default: null,
    },
    paymentMethodOptions: {
      type: Array as PropType<ICheckoutPaymentMethods>,
      default: () => [],
    },
    selectedPaymentMethod: {
      type: Object as PropType<ICheckoutPaymentMethod>,
      default: null,
    },
  },
  data(): ICheckoutFormFields {
    return {
      email: this.userEmail,
      firstName: this.userFirstName,
      lastName: this.userLastName,
      businessName: this.userBusinessName,
      address: this.userAddress,
      phoneNumber: this.userPhoneNumber,
      shippingMethod: this.selectedShippingMethod,
      deliveryInstructions: '',
      paymentMethod: this.selectedPaymentMethod,
    };
  },
  methods: {
    onAddressSelected(addressSelected: IAddressSearchResultItem): void {
      this.address = addressSelected;
      this.$emit('address-selected', this.address);
    },
    onAddressCleared(): void {
      this.address = null;
      this.$emit('address-cleared');
    },
    onShippingMethodSelected(selectedShippingMethod: ICheckoutShippingMethod): void {
      this.$emit('shipping-method-selected', selectedShippingMethod);
    },
    onPaymentMethodSelected(selectedPaymentMethod: ICheckoutPaymentMethod): void {
      this.$emit('payment-method-selected', selectedPaymentMethod);
    },
    onCheckoutFormSubmit(): void {
      this.$emit('checkout-submit', {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        businessName: this.businessName,
        address: this.address,
        phoneNumber: this.phoneNumber,
        shippingMethod: this.shippingMethod,
        deliveryInstructions: this.deliveryInstructions,
        paymentMethod: this.paymentMethod,
      });
    },
  },
};
