









import BaseButton from '../../atoms/BaseButton/BaseButton.vue';

export default {
  name: 'BaseVehicleSelector',
  components: {
    BaseButton,
  },
};
