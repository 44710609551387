interface ImageOptions {
  /**
   * Image should not be auto-croppped but auto-resized (shrunk).
   * @default false
   */
  fitIn?: boolean;

  /**
   * Resizes the image according to the specified width dimension.
   *
   * If no `height` dimension is passed, then the image is resized proportional to the width.
   */
  width?: number;

  /**
   * Resizes the image according to the specified height dimension.
   *
   * If no `width` dimension is passed, then the image is resized proportional to the height.
   */
  height?: number;
}

export type getStoryblokImageURL = (imageSrc: string, options?: ImageOptions) => string;

const getStoryblokImageURL: getStoryblokImageURL = (
  imageSrc = '',
  options?: ImageOptions,
): string => {
  if (!imageSrc) return imageSrc;

  let optimisations = '/m';

  const isSvg = imageSrc.endsWith('.svg');
  if (isSvg) {
    return imageSrc;
  }

  if (options?.fitIn) {
    optimisations += '/fit-in';
  }

  let dimensions = '';

  if (options?.width && options?.height) {
    dimensions = `${options.width}x${options.height}`;
  } else if (options?.width && !options?.height) {
    dimensions = `${options.width}x0`;
  } else if (!options?.width && options?.height) {
    dimensions = `0x${options.height}`;
  }

  if (dimensions) {
    optimisations += `/${dimensions}`;
  }

  if (options?.fitIn) {
    optimisations += '/filters:fill(transparent):format(webp)';
  } else {
    optimisations += '/filters:format(webp)';
  }

  optimisations += ':quality(80)';

  return `${imageSrc}${optimisations}/`;
};

export default getStoryblokImageURL;
