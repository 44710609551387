import getAndEnhanceProducts from '@composables/src/helpers/internals/getAndEnhanceProducts';
import { Context, Logger } from '@vue-storefront/core';

import { ComponentReference } from './types';

export default async function batchResolveProductsBySku(
  context: Context,
  shopProductRefs: ComponentReference[],
) {
  const skus = new Set(
    shopProductRefs.map((ref) => {
      if (hasVariants(ref)) {
        return ref.component?.shopify_product?.items?.[0]?.variants?.[0].sku;
      } else {
        return ref.component?.shopify_product?.items?.[0]?.sku;
      }
    }),
  );

  const skuFilter = Array.from(skus).join(' OR ');

  const productsResponse = await context.$shopify.api.searchProducts({
    query: skuFilter,
    first: skus.size,
  });

  const enhancedProducts = getAndEnhanceProducts(productsResponse?.data?.products) ?? [];

  // iterate over the matches and find the data. if no data set null
  const shopProductRefsWithData = shopProductRefs.map((reference) => {
    const data = enhancedProducts.find((product) => {
      if (hasVariants(reference)) {
        return !!product.variants?.filter(
          (el) => el.sku === reference.component?.shopify_product?.items?.[0]?.variants?.[0].sku,
        ).length;
      } else {
        return product.variants?.[0]?.sku === reference.component?.shopify_product?.items?.[0]?.sku;
      }
    });
    if (!data) {
      Logger.warn(
        `Shopify Integration::batchResolveProductsBySku() - Product not found: SKU:${reference.component?.shopify_product?.items?.[0]?.sku} ID:${reference.component?.shopify_product?.items?.[0]?.id} \n`,
      );
    }

    return {
      ...reference,
      data: data ? [data] : null, // in an array because shop products are expected to be this way in front end
    };
  });
  // filter out null results

  const shopProductData = shopProductRefsWithData.filter((ref) => ref.data);

  return shopProductData;
}

function hasVariants(ref: ComponentReference): boolean {
  return (
    ref.component?.shopify_product?.items?.[0]?.sku === false &&
    ref.component?.shopify_product?.items?.[0]?.variants
  );
}
