

















































import { PropType } from 'vue';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseMaterialIcon from '../../atoms/BaseMaterialIcon/BaseMaterialIcon.vue';
import BaseDropdown from '../../molecules/BaseDropdown/BaseDropdown.vue';
import BaseList from '../../organisms/BaseList/BaseList.vue';
import BaseListItem from '../../atoms/BaseListItem/BaseListItem.vue';
import { IProductTypeSelectorData, IProductTypeItem, IProductTypes } from './types';
import { STUB_PRODUCT_TYPES } from './constants';

export default {
  name: 'BaseProductTypeSelector',
  components: {
    BaseDropdown,
    BaseList,
    BaseListItem,
    BaseButton,
    BaseMaterialIcon,
  },
  props: {
    productTypes: {
      type: Object as PropType<IProductTypes>,
      default: (): IProductTypes => STUB_PRODUCT_TYPES,
    },
    productTypeSelectedByDefault: {
      type: String,
      default: 'all',
    },
    selectorOpenedByDefault: {
      type: Boolean,
      default: false,
    },
  },
  data(): IProductTypeSelectorData {
    return {
      isSelectorOpen: this.selectorOpenedByDefault,
      productTypeSelected: STUB_PRODUCT_TYPES[this.productTypeSelectedByDefault],
    };
  },
  methods: {
    selectProductType(productTypeSelected: IProductTypeItem): void {
      this.productTypeSelected = productTypeSelected;
      this.$emit('selected-product-type', this.productTypeSelected);
      this.closeSelector();
    },
    toggleSelector(): void {
      this.isSelectorOpen = !this.isSelectorOpen;
    },
    closeSelector(): void {
      this.isSelectorOpen = false;
    },
  },
};
