import { IPaymentMethods } from './types';

export const STUB_PAYMENT_METHODS: IPaymentMethods = [
  {
    label: 'MasterCard',
    imgSrc: '/assets/storybook/BasePaymentMethods/mastercard.png',
  },
  {
    label: 'VISA',
    imgSrc: '/assets/storybook/BasePaymentMethods/visa.png',
  },
  {
    label: 'American Express',
    imgSrc: '/assets/storybook/BasePaymentMethods/amex.png',
  },
  {
    label: 'PayPal',
    imgSrc: '/assets/storybook/BasePaymentMethods/paypal.png',
  },
  {
    label: 'Afterpay',
    imgSrc: '/assets/storybook/BasePaymentMethods/afterpay.svg',
  },
  {
    label: 'Zip Pay',
    imgSrc: '/assets/storybook/BasePaymentMethods/zippay.png',
  },
];
