






















import { PropType } from 'vue';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import { ICheckoutShippingMethod, ICheckoutShippingMethods } from './types';
import { STUB_SHIPPING_METHODS } from './constants';

export default {
  name: 'BaseCheckoutShippingMethodsSelector',
  components: {
    BaseButton,
  },
  props: {
    shippingMethods: {
      type: Array as PropType<ICheckoutShippingMethods>,
      default: (): ICheckoutShippingMethods => STUB_SHIPPING_METHODS,
    },
    selectedShippingMethod: {
      type: Object as PropType<ICheckoutShippingMethod>,
      default: null,
    },
  },
  methods: {
    select(selectedShippingMethod: ICheckoutShippingMethod): void {
      this.$emit('select', selectedShippingMethod);
    },
  },
};
