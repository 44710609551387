import { IAddressComponent, IAddressType } from '@api-client/src/api/getPlaceDetails/types';
import { IGeocodeSuburbResponse } from '../types';

export interface ILocation {
  label: string;
  postcode: string;
  state: string;
  suburb: string;
}

const isValidPostcode = (geocodeSuburbResponse: IGeocodeSuburbResponse) => {
  return !geocodeSuburbResponse?.results[0]?.partial_match;
}

const hasMultipleSuburbs = (geocodeSuburbResponse: IGeocodeSuburbResponse) => {
  return !!geocodeSuburbResponse?.results[0]?.postcode_localities?.length;
}

const getSuburbsFromResponse = (geocodeSuburbResponse: IGeocodeSuburbResponse): ILocation[] => {
  if (!geocodeSuburbResponse || !isValidPostcode(geocodeSuburbResponse)) {
    return [];
  }

  const createLocation = (components: IAddressComponent[], label: string): ILocation => {
    const location: ILocation = {
      label,
      postcode: '',
      state: '',
      suburb: '',
    };

    components.forEach((component) => {
      if (component.types.includes(IAddressType.LOCALITY)) {
        location.suburb = component.short_name;
      } else if (component.types.includes(IAddressType.POSTAL_CODE)) {
        location.postcode = component.short_name;
      } else if (component.types.includes(IAddressType.ADMINISTRATIVE_AREA_LEVEL_1)) {
        location.state = component.short_name;
      }
    });

    return location;
  };

  const result = geocodeSuburbResponse.results[0];
  if (!hasMultipleSuburbs(geocodeSuburbResponse)) {
    const location = createLocation(result?.address_components, result?.formatted_address);
    return [location];
  }

  const baseLocation = createLocation(result?.address_components || [], '');
  const locations = result?.postcode_localities?.map(suburb => ({
    ...baseLocation,
    suburb,
    label: `${suburb} ${baseLocation.state} ${baseLocation.postcode}, Australia`,
  })) || [];

  return locations;
};


export default {
  getSuburbsFromResponse,
};
