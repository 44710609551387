/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Context,
  ProductsSearchParams,
  useProductFactory,
  UseProductFactoryParams,
} from '@vue-storefront/core';

import logThrottledRequests from '../helpers/logThrottledRequests';
import { ProductsResponse } from '../types';

const params: UseProductFactoryParams<ProductsResponse, any> = {
  productsSearch: async (
    context: Context,
    params: ProductsSearchParams,
  ): Promise<ProductsResponse> => {
    // check if cart is already initiated
    const { customQuery, ...searchParams } = params;

    if (params.recommended) {
      const response = await context.$shopify.api.getRecommendedProducts(params);

      logThrottledRequests('getRecommendedProducts', params, response);

      return response;
    }

    const response = await context.$shopify.api.getProduct(searchParams, customQuery);

    logThrottledRequests('getProduct', params, response);

    return response;
  },
};

const useProduct = useProductFactory<ProductsResponse, any>(params);

export default useProduct;
