import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import resolveShopifyData from './resolveShopifyData';
import { UseStoryblokResolverError, UseStoryblokResolverLoading } from './types';

const useStoryblokResolver = () => {
  const context = useVSFContext();

  const loading = sharedRef(false, 'useStoryblokResolver/loading');

  const error = sharedRef(null, 'useStoryblokResolver/error');

  const refs = {
    loading,
    error,
  };

  return {
    resolveShopifyData: resolveShopifyData(context, refs),
    loading: computed<UseStoryblokResolverLoading>(() => loading.value),
    error: computed<UseStoryblokResolverError>(() => error.value),
  };
};

export default useStoryblokResolver;
