import {
  FitmentRecord,
  IProductResult,
  ISearchProductsResponse,
  ISearchResponse,
} from '@api-client/src/api/search/types';

export const CATEGORY_TITLE_COUNT = 'count.collection_titles';

const getItems = <T>(response: ISearchResponse<T>): T | null =>
  response ? response.results : null;

const getFittingPos = (fitmentRecord: FitmentRecord): string => {
  // .split('::') becuase data is in format "engine bay::<number>"
  return fitmentRecord.fittingpositions?.split('::')[0] ?? '';
};

const getFitmentNote = (fitmentRecord: FitmentRecord): string => {
  return fitmentRecord.panote ?? '';
};

const getQtyPerVehicle = (fitmentRecord: FitmentRecord): string => {
  return fitmentRecord.qtyper ?? '';
};

const getAllASKUs = (response: ISearchProductsResponse): string[] => {
  if (!response.results) return [];
  return response.results.reduce((acc: string[], item: IProductResult) => {
    const ASKUs_array = [...acc];
    if (typeof item.record?.metafield_plytix_asku === 'string') {
      ASKUs_array.push(item.record.metafield_plytix_asku);
    }
    if (
      item.record?.variant_metafield_plytix_asku &&
      Array.isArray(item.record?.variant_metafield_plytix_asku)
    ) {
      ASKUs_array.push(...item.record.variant_metafield_plytix_asku);
    }
    return ASKUs_array.filter((item) => item !== '');
  }, [] as string[]);
};

const getPipelineVariable = (response: ISearchProductsResponse, variable: string) =>
  response.variables?.[variable];

const getPageCount = (response: ISearchProductsResponse) =>
  Math.ceil(response.totalSize / response.resultsPerPage);

const getItemCount = (response: ISearchProductsResponse) => response.totalSize;

const hasNoItems = (response: ISearchProductsResponse) => response.totalSize === 0;

const hasPagination = (response: ISearchProductsResponse) => {
  const pageCount = getPageCount(response);

  return pageCount > 1;
};

const getCategories = (response: ISearchProductsResponse) => {
  if (!response) {
    return null;
  }
  return response?.aggregateFilters[CATEGORY_TITLE_COUNT]?.count?.counts;
};

const searchGetters = {
  getPipelineVariable,
  getItems,
  getPageCount,
  getItemCount,
  hasPagination,
  getCategories,
  hasNoItems,
  getAllASKUs,
  getFitmentNote,
  getFittingPos,
  getQtyPerVehicle,
};

export default searchGetters;
