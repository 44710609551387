import { IAccountLinks } from './types';

export const STUB_ACCOUNT_LINKS: IAccountLinks = [
  {
    label: 'Log In',
    link: '#',
    icon: 'person',
    showIfLoggedIn: false,
  },
  {
    label: 'Create Account',
    link: '#',
    icon: 'person_add_alt_1',
    showIfLoggedIn: false,
  },
  {
    prefix: 'MY GARAGE:',
    label: 'ADD VEHICLE',
    link: '#',
    icon: 'directions_car',
    color: 'var(--c-dark)',
    alwaysShow: true,
  },
];
