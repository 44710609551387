
































import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseIcon from '../../atoms/BaseIcon/BaseIcon.vue';
import BaseImage from '../../atoms/BaseImage/BaseImage.vue';

export default {
  name: 'BaseAddedToCartPopup',
  components: {
    BaseButton,
    BaseIcon,
    BaseImage,
  },
  props: {
    productThumbnailSrc: {
      type: String,
      default: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
    },
    productName: {
      type: String,
      default: 'Tridon Emergency Cap',
    },
    productPrice: {
      type: String,
      default: '7.95',
    },
    productQuantity: {
      type: Number,
      default: 1,
    },
    cartLink: {
      type: String,
      default: '#',
    },
    checkoutLink: {
      type: String,
      default: '#',
    },
  },
  methods: {
    close(): void {
      this.$emit('close');
    },
  },
};
