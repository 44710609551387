






























import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseLink from '../../atoms/BaseLink/BaseLink.vue';
import BaseInputWithIcon from '../../atoms/BaseInputWithIcon/BaseInputWithIcon.vue';
import { IAccountLoginPassword } from './types';

export default {
  name: 'BaseCheckoutAccountLogin',
  components: {
    BaseButton,
    BaseLink,
    BaseInputWithIcon,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    forgotPasswordLink: {
      type: String,
      default: '#',
    },
  },
  data(): IAccountLoginPassword {
    return {
      password: '',
    };
  },
  methods: {
    onPasswordInput(password: string): void {
      this.password = password;
    },
    submit(): void {
      this.$emit('login', { email: this.email, password: this.password });
    },
    continueAsGuest(): void {
      this.$emit('continue-as-guest', this.email);
    },
    goBackToEmail(): void {
      this.$emit('back-to-email');
    },
  },
};
