




























































































import { PropType } from 'vue';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseIcon from '../../atoms/BaseIcon/BaseIcon.vue';
import BaseMaterialIcon from '../../atoms/BaseMaterialIcon/BaseMaterialIcon.vue';
import BaseSidebar from '../../organisms/BaseSidebar/BaseSidebar.vue';
import BaseAccordion from '../../organisms/BaseAccordion/BaseAccordion.vue';
import BaseAccordionItem from '../../atoms/BaseAccordionItem/BaseAccordionItem.vue';
import BaseLink from '../../atoms/BaseLink/BaseLink.vue';
import BaseList from '../../organisms/BaseList/BaseList.vue';
import BaseListItem from '../../atoms/BaseListItem/BaseListItem.vue';
import BaseMenuItem from '../../molecules/BaseMenuItem/BaseMenuItem.vue';
import { STUB_HEADER_MENU_CATEGORIES } from './constants';
import { IHeaderMenuCategories } from './types';

export default {
  name: 'BaseHeaderMenu',
  components: {
    BaseButton,
    BaseIcon,
    BaseMaterialIcon,
    BaseSidebar,
    BaseAccordion,
    BaseAccordionItem,
    BaseLink,
    BaseList,
    BaseListItem,
    BaseMenuItem,
  },
  props: {
    categories: {
      type: Array as PropType<IHeaderMenuCategories>,
      default: (): IHeaderMenuCategories => STUB_HEADER_MENU_CATEGORIES,
      validator: (categories: IHeaderMenuCategories): boolean =>
        Array.isArray(categories) && categories.length > 0,
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
    defaultCategoryOpened: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeMenu(): void {
      this.$emit('close-menu');
    },
  },
};
