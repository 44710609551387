import { IFilterList, ISort } from './types';

export const STUB_FILTER_LIST_PROP = <Array<IFilterList>>[
  {
    name: 'Price',
    kind: 'range',
    rangeOptions: {
      min: 10,
      max: 3000,
    },
  },
  {
    name: 'Fitment Position',
    kind: 'checkbox',
    checkboxOptions: [
      {
        label: 'Engine',
        value: 'engine',
        name: 'engine',
        trailingNumber: 2516,
        selected: false,
      },
      {
        label: 'Lower',
        value: 'lower',
        name: 'lower',
        trailingNumber: 1122,
        selected: false,
      },
      {
        label: 'Upper',
        value: 'upper',
        name: 'upper',
        trailingNumber: 4231,
        selected: false,
      },
      {
        label: 'Cooling System',
        value: 'cooling-system',
        name: 'cooling-system',
        trailingNumber: 2323,
        selected: false,
      },
      {
        label: 'Rear',
        value: 'rear',
        name: 'rear',
        trailingNumber: 23124,
        selected: false,
      },
      {
        label: 'Front LH',
        value: 'front-lh',
        name: 'front-lh',
        trailingNumber: 473,
        selected: false,
      },
      {
        label: 'Front RH',
        value: 'front-rh',
        name: 'front-rh',
        trailingNumber: 464,
        selected: false,
      },
      {
        label: 'Front',
        value: 'front',
        name: 'front',
        trailingNumber: 438,
        selected: false,
      },
      {
        label: 'Right',
        value: 'right',
        name: 'right',
        trailingNumber: 260,
        selected: false,
      },
      {
        label: 'Left',
        value: 'left',
        name: 'left',
        trailingNumber: 225,
        selected: false,
      },
    ],
  },
  {
    name: 'Promotion',
    kind: 'checkbox',
    checkboxOptions: [
      {
        label: 'Spring Deal',
        value: 'spring-deal',
        name: 'spring-deal',
        trailingNumber: 1124,
        selected: false,
      },
      {
        label: 'Pad Deal',
        value: 'pad-deal',
        name: 'pad-deal',
        trailingNumber: 458,
        selected: false,
      },
      {
        label: 'Ryco20',
        value: 'ryco-20',
        name: 'ryco-20',
        trailingNumber: 175,
        selected: false,
      },
      {
        label: 'Grip Deal',
        value: 'grip-deal',
        name: 'grip-deal',
        trailingNumber: 68,
        selected: false,
      },
    ],
  },
  {
    name: 'Types',
    kind: 'checkbox',
    checkboxOptions: [
      {
        label: 'Hoses - Radiator',
        name: 'types-hoses-radiator',
        value: 'hoses-radiator',
        trailingNumber: 6620,
        selected: false,
      },
      {
        label: 'Clutch Kits - OE Replacement',
        name: 'types-clutch-kits-oe-replacement',
        value: 'clutch-kits-oe-replacement',
        trailingNumber: 5285,
        selected: false,
      },
      {
        label: 'Sensors - 02 Oxygen',
        name: 'types-sensors-02-oxygen',
        value: 'sensors-02-oxygen',
        trailingNumber: 2902,
        selected: false,
      },
      {
        label: 'Accessory Belts',
        name: 'types-accessory-belts',
        value: 'accessory-belts',
        trailingNumber: 2851,
        selected: false,
      },
      {
        label: 'Hoses - Heater',
        name: 'types-hoses-heater',
        value: 'hoses-heater',
        trailingNumber: 2717,
        selected: false,
      },
      {
        label: 'Clutch Kits - Performance',
        name: 'types-clutch-kits-performance',
        value: 'clutch-kits-performance',
        trailingNumber: 2392,
        selected: false,
      },
      {
        label: 'Water Pumps',
        name: 'types-water-pumps',
        value: 'water-pumps',
        trailingNumber: 2178,
        selected: false,
      },
      {
        label: 'Engine Mounts',
        name: 'types-engine-mounts',
        value: 'engine-mounts',
        trailingNumber: 2102,
        selected: false,
      },
      {
        label: 'Ignition Coils',
        name: 'types-ignition-coils',
        value: 'ignition-coils',
        trailingNumber: 1447,
        selected: false,
      },
      {
        label: 'Bushes - Engine Mount',
        name: 'types-bushes-engine-mount',
        value: 'bushes-engine-mount',
        trailingNumber: 1335,
        selected: false,
      },
      {
        label: 'Clutch Kits - Heavy Duty',
        name: 'types-clutch-kits-heavy-duty',
        value: 'clutch-kits-heavy-duty',
        trailingNumber: 1182,
        selected: false,
      },
      {
        label: 'Fuel Pumps',
        name: 'types-fuel-pumps',
        value: 'fuel-pumps',
        trailingNumber: 1121,
        selected: false,
      },
      {
        label: 'Timing Belts',
        name: 'types-timing-belts',
        value: 'timing-belts',
        trailingNumber: 1081,
        selected: false,
      },
      {
        label: 'Timing Belt Kits',
        name: 'types-timing-belt-kits',
        value: 'timing-belt-kits',
        trailingNumber: 1026,
        selected: false,
      },
      {
        label: 'Thermostats - Standard Replacement',
        name: 'types-thermostats-standard-replacement',
        value: 'thermostats-standard-replacement',
        trailingNumber: 956,
        selected: false,
      },
    ],
  },
  {
    name: 'Material',
    kind: 'checkbox',
    checkboxOptions: [
      {
        label: 'Aluminium',
        name: 'material-aluminium',
        value: 'aluminium',
        selected: false,
        trailingNumber: 3225,
      },
      {
        label: 'Stainless Steeel',
        name: 'material-stainless-steeel',
        value: 'stainless-steeel',
        selected: false,
        trailingNumber: 1675,
      },
      {
        label: 'Silicone',
        name: 'material-silicone',
        value: 'silicone',
        selected: false,
        trailingNumber: 1053,
      },
      {
        label: 'Aluminised Steel',
        name: 'material-aluminised-steel',
        value: 'aluminised-steel',
        selected: false,
        trailingNumber: 711,
      },
      {
        label: 'Metal',
        name: 'material-metal',
        value: 'metal',
        selected: false,
        trailingNumber: 266,
      },
      {
        label: 'Stainless',
        name: 'material-stainless',
        value: 'stainless',
        selected: false,
        trailingNumber: 126,
      },
      {
        label: 'Plastic',
        name: 'material-plastic',
        value: 'plastic',
        selected: false,
        trailingNumber: 125,
      },
      {
        label: 'Mild Steel',
        name: 'material-mild-steel',
        value: 'mild-steel',
        selected: false,
        trailingNumber: 69,
      },
      {
        label: 'Stainless Steel Braided',
        name: 'material-stainless-steel-braided',
        value: 'stainless-steel-braided',
        selected: false,
        trailingNumber: 44,
      },
      {
        label: 'Rubber',
        name: 'material-rubber',
        value: 'rubber',
        selected: false,
        trailingNumber: 43,
      },
      {
        label: 'Alloy',
        name: 'material-alloy',
        value: 'alloy',
        selected: false,
        trailingNumber: 36,
      },
      {
        label: 'Iron',
        name: 'material-iron',
        value: 'iron',
        selected: false,
        trailingNumber: 26,
      },
      {
        label: 'Nylon',
        name: 'material-nylon',
        value: 'nylon',
        selected: false,
        trailingNumber: 20,
      },
      {
        label: 'Titanium',
        name: 'material-titanium',
        value: 'titanium',
        selected: false,
        trailingNumber: 14,
      },
      {
        label: 'PTFE',
        name: 'material-ptfe',
        value: 'ptfe',
        selected: false,
        trailingNumber: 11,
      },
    ],
  },
  {
    name: 'Multipack',
    kind: 'checkbox',
    checkboxOptions: [
      {
        label: 'Singular',
        name: 'multipack-singular',
        value: 'singular',
        selected: false,
        trailingNumber: 3173,
      },
      {
        label: 'Single Plug',
        name: 'multipack-single-plug',
        value: 'single-plug',
        selected: false,
        trailingNumber: 330,
      },
      {
        label: 'Value Pack',
        name: 'multipack-value-pack',
        value: 'value-pack',
        selected: false,
        trailingNumber: 100,
      },
    ],
  },
  {
    name: 'Colour',
    kind: 'checkbox',
    checkboxOptions: [
      {
        label: 'Black',
        name: 'colour-black',
        value: 'black',
        selected: false,
        trailingNumber: 4231,
      },
      {
        label: 'Blue',
        name: 'colour-blue',
        value: 'blue',
        selected: false,
        trailingNumber: 2154,
      },
      {
        label: 'Silver',
        name: 'colour-silver',
        value: 'silver',
        selected: false,
        trailingNumber: 348,
      },
    ],
  },
  {
    name: 'Power',
    kind: 'checkbox',
    checkboxOptions: [
      {
        label: '400-750 HP',
        name: 'power-400-750-hp',
        value: '400-750-hp',
        selected: false,
        trailingNumber: 23,
      },
      {
        label: '550-1000 HP',
        name: 'power-550-1000-hp',
        value: '550-1000-hp',
        selected: false,
        trailingNumber: 14,
      },
      {
        label: '1000-2500 HP',
        name: 'power-1000-2500-hp',
        value: '1000-2500-hp',
        selected: false,
        trailingNumber: 5,
      },
    ],
  },
  {
    name: 'Capacity',
    kind: 'checkbox',
    checkboxOptions: [
      {
        label: '82 Degrees C',
        name: 'capacity-82-degrees-c',
        value: '82-degrees-c',
        selected: false,
        trailingNumber: 280,
      },
      {
        label: '88 Degrees C',
        name: 'capacity-88-degrees-c',
        value: '88-degrees-c',
        selected: false,
        trailingNumber: 174,
      },
      {
        label: '92 Degrees C',
        name: 'capacity-92-degrees-c',
        value: '92-degrees-c',
        selected: false,
        trailingNumber: 51,
      },
    ],
  },
  {
    name: 'Size',
    kind: 'checkbox',
    checkboxOptions: [
      {
        label: '-6 AN Hose',
        name: 'size--6-an-hose',
        value: '-6-an-hose',
        selected: false,
        trailingNumber: 221,
      },
      {
        label: '-8 AN Hose',
        name: 'size--8-an-hose',
        value: '-8-an-hose',
        selected: false,
        trailingNumber: 150,
      },
      {
        label: '-10 AN Hose',
        name: 'size--10-an-hose',
        value: '-10-an-hose',
        selected: false,
        trailingNumber: 80,
      },
    ],
  },
  {
    name: 'Amp',
    kind: 'checkbox',
    checkboxOptions: [
      {
        label: '80 A',
        name: 'amp-80-a',
        value: '80-a',
        selected: false,
        trailingNumber: 19,
      },
      {
        label: '90 A',
        name: 'amp-90-a',
        value: '90-a',
        selected: false,
        trailingNumber: 10,
      },
      {
        label: '100 A',
        name: 'amp-100-a',
        value: '100-a',
        selected: false,
        trailingNumber: 5,
      },
    ],
  },
  {
    name: 'Volt',
    kind: 'checkbox',
    checkboxOptions: [
      {
        label: '12 V',
        name: 'volt-12-v',
        value: '12-v',
        selected: false,
        trailingNumber: 258,
      },
      {
        label: '24 V',
        name: 'volt-24-v',
        value: '24-v',
        selected: false,
        trailingNumber: 22,
      },
      {
        label: '12/24 V',
        name: 'volt-12-24-v',
        value: '12-24-v',
        selected: false,
        trailingNumber: 3,
      },
    ],
  },
];

export const STUB_SORT_LIST_PROP = <Array<ISort>>[
  {
    label: 'Recommended',
    value: 'recommended',
  },
  {
    label: 'Lowest Price',
    value: 'lowest-price',
  },
  {
    label: 'Highest Price',
    value: 'highest-price',
  },
  {
    label: 'Brand A - Z',
    value: 'brand-asc',
  },
  {
    label: 'Brand Z - A',
    value: 'brand-desc',
  },
  {
    label: 'Name A - Z',
    value: 'name-asc',
  },
  {
    label: 'Name Z - A',
    value: 'name-desc',
  },
];

export const STUB_PRODUCTS_PER_PAGE_LIST_PROP = <Array<string>>['25', '50', '100'];
