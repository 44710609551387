



































import BaseButton from '../BaseButton/BaseButton.vue';
import BaseChevron from '../BaseChevron/BaseChevron.vue';

export default {
  name: 'BaseAccordionItem',
  components: {
    BaseChevron,
    BaseButton,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    chevronPositions: {
      type: Object,
      default: () => ({
        closed: 'down',
        open: 'right',
      }),
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    amIOpen(): boolean {
      return this.isOpen || this.alwaysOpen;
    },
  },
  methods: {
    accordionClick() {
      this.$parent.$emit('toggle', (this as any)._uid);
    },
  },
};
