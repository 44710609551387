





































import { PropType } from 'vue';
import BaseLink from '../../atoms/BaseLink/BaseLink.vue';
import BaseImage from '../../atoms/BaseImage/BaseImage.vue';
import { STUB_FOOTER_NAV_LINKS, STUB_FOOTER_SOCIAL_LINKS } from './constants';
import { IFooterNavLinks, IFooterSocialLinks } from './types';

export default {
  name: 'BaseFooterLinks',
  components: {
    BaseLink,
    BaseImage,
  },
  props: {
    navLinks: {
      type: Array as PropType<IFooterNavLinks>,
      default: (): IFooterNavLinks => STUB_FOOTER_NAV_LINKS,
    },
    socialLinks: {
      type: Array as PropType<IFooterSocialLinks>,
      default: (): IFooterSocialLinks => STUB_FOOTER_SOCIAL_LINKS,
    },
  },
};
