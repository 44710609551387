import { IAddressSearchResults } from './types';

export const STUB_ADDRESS_SEARCH_RESULTS: IAddressSearchResults = [
  {
    street: '75 Mary Street',
    country: 'Australia',
    postcode: '2194',
    stateProvince: 'NSW',
    suburbCity: 'St. Peters',
  },
  {
    street: '76 Mary Street',
    country: 'Australia',
    postcode: '2194',
    stateProvince: 'NSW',
    suburbCity: 'St. Peters',
  },
  {
    street: '77 Mary Street',
    country: 'Australia',
    postcode: '2194',
    stateProvince: 'NSW',
    suburbCity: 'St. Peters',
  },
  {
    street: '78 Mary Street',
    country: 'Australia',
    postcode: '2194',
    stateProvince: 'NSW',
    suburbCity: 'St. Peters',
  },
  {
    street: '79 Mary Street',
    country: 'Australia',
    postcode: '2194',
    stateProvince: 'NSW',
    suburbCity: 'St. Peters',
  },
  {
    street: '80 Mary Street',
    country: 'Australia',
    postcode: '2194',
    stateProvince: 'NSW',
    suburbCity: 'St. Peters',
  },
  {
    street: '81 Mary Street',
    country: 'Australia',
    postcode: '2194',
    stateProvince: 'NSW',
    suburbCity: 'St. Peters',
  },
  {
    street: '82 Mary Street',
    country: 'Australia',
    postcode: '2194',
    stateProvince: 'NSW',
    suburbCity: 'St. Peters',
  },
  {
    street: '83 Mary Street',
    country: 'Australia',
    postcode: '2194',
    stateProvince: 'NSW',
    suburbCity: 'St. Peters',
  },
  {
    street: '84 Mary Street',
    country: 'Australia',
    postcode: '2194',
    stateProvince: 'NSW',
    suburbCity: 'St. Peters',
  },
];
