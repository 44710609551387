








































































































































import { colorsValues as SF_COLORS } from '@storefront-ui/shared/variables/colors';
import {
  mapMobileObserver,
  unMapMobileObserver,
} from '@storefront-ui/vue/src/utilities/mobile-observer';
import Vue from 'vue';

import BaseBadge from '../../atoms/BaseBadge/BaseBadge.vue';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseCircleIcon from '../../atoms/BaseCircleIcon/BaseCircleIcon.vue';
import BaseIcon from '../../atoms/BaseIcon/BaseIcon.vue';
import BaseImage from '../../atoms/BaseImage/BaseImage.vue';
import BasePrice from '../../atoms/BasePrice/BasePrice.vue';
import BaseRating from '../../atoms/BaseRating/BaseRating.vue';

export default Vue.extend({
  name: 'BaseProductCard',
  components: {
    BasePrice,
    BaseRating,
    BaseIcon,
    BaseImage,
    BaseCircleIcon,
    BaseBadge,
    BaseButton,
  },
  props: {
    image: {
      type: [Array, Object, String],
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    badgeLabel: {
      type: String,
      default: '',
    },
    badgeColor: {
      type: String,
      default: '',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    fallbackImageSrc: {
      type: String,
      default: null,
    },
    link: {
      type: [String, Object],
      default: null,
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      default: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: '',
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isAddingToCart: false,
      openColorPicker: false,
    };
  },
  computed: {
    ...mapMobileObserver(),
    isBaseColors() {
      return SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass() {
      return this.isBaseColors ? `${this.badgeColor.trim()}` : '';
    },
    showAddedToCartBadge() {
      return !this.isAddingToCart && this.isAddedToCart;
    },
    showBadge() {
      return this.colors.length > 5;
    },
  },
  beforeDestroy() {
    unMapMobileObserver();
  },
  methods: {
    onAddToCart(event): void {
      event.preventDefault();
      this.isAddingToCart = true;
      const id = setTimeout(() => {
        this.isAddingToCart = false;
        clearTimeout(id);
      }, 1000);
      this.$emit('click:add-to-cart');
    },
    handleSelectedColor(colorIndex): void {
      if (this.colors.length > 0) {
        this.colors.map((color, i) => {
          if (colorIndex === i) {
            this.$emit('click:colors', color);
            if (this.isMobile) {
              this.toggleColorPicker();
            }
          }
        });
      }
    },
    toggleColorPicker(): void {
      this.openColorPicker = !this.openColorPicker;
    },
  },
});
