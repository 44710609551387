






export default {
  name: 'BaseScrollable',
};
