/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Context,
  ProductsSearchParams,
  useProductFactory,
  UseProductFactoryParams,
} from '@vue-storefront/core';

import { ProductsResponse } from '../types';

const params: UseProductFactoryParams<ProductsResponse, any> = {
  productsSearch: (context: Context, params: ProductsSearchParams): Promise<ProductsResponse> => {
    return context.$shopify.api.getRecommendedProductsV1(params);
  },
};

const useProductRecommendationsV1 = useProductFactory<ProductsResponse, any>(params);

export default useProductRecommendationsV1;
