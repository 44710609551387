









































import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import Vue, { PropType } from 'vue';

import imagePlaceholder from './image_placeholder.svg';

interface ISrcSetItem {
  src: string;
  resolution?: string;
  width?: string;
  breakpoint?: string;
}

type IImageSize = number | string;

interface IAttributes {
  [key: string]: unknown;
  sizes?: string;
  width?: IImageSize;
  height?: IImageSize;
  srcset?: string;
}

export default Vue.extend({
  name: 'BaseImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    srcsets: {
      type: Array as PropType<Readonly<ISrcSetItem[]>>,
      default: () => [],
      validator: (value: ISrcSetItem[]) =>
        value.every((item: ISrcSetItem) => item.src && (item?.resolution || item?.width)),
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number] as PropType<IImageSize>,
      default: null,
      validator: (value) => isString(value) || isNumber(value),
    },
    height: {
      type: [String, Number] as PropType<IImageSize>,
      default: null,
      validator: (value) => isString(value) || isNumber(value),
    },
    placeholder: {
      type: String,
      default: imagePlaceholder,
    },
    fallbackImageSrc: {
      type: String,
      default: null,
    },
    loading: {
      type: String as PropType<'lazy' | 'eager'>,
      default: 'lazy',
      validator: (value) => ['lazy', 'eager'].includes(value),
    },
    imageTag: {
      type: String as PropType<'img' | 'nuxt-img' | 'nuxt-picture'>,
      default: 'img',
      validator: (value) => ['img', 'nuxt-img', 'nuxt-picture'].includes(value),
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    sortedSrcsets(): ISrcSetItem[] {
      const arr = [...(this.srcsets as ISrcSetItem[])];

      arr.sort((setA: ISrcSetItem, setB: ISrcSetItem) =>
        setA?.width && setB?.width
          ? Number.parseInt(setA.width) - Number.parseInt(setB.width)
          : Number.parseInt(setA?.resolution || '') - Number.parseInt(setB.resolution || ''),
      );
      return arr;
    },
    srcset(): string {
      return ((this.sortedSrcsets || []) as ISrcSetItem[]).reduce(
        (str: string, set: ISrcSetItem) =>
          `${this.prefix(str)}${set.src} ${this.srcsetDescriptor(set)}`,
        '',
      );
    },
    sizes(): IImageSize | undefined {
      const hasBreakpoints = this.sortedSrcsets.every(
        (set: ISrcSetItem) => set.breakpoint && set.width,
      );

      if (!hasBreakpoints) return;

      return this.sortedSrcsets.reduce(
        (str: string, set: ISrcSetItem) =>
          `${this.prefix(str)}${this.formatBreakpoint(set.breakpoint)}${this.formatDimension(
            set.width,
          )}`,
        '',
      );
    },
    classes(): string {
      if (this.loaded) {
        return 'base-image base-image-loaded';
      } else {
        return 'base-image';
      }
    },
    isPlaceholderVisible(): boolean {
      return (
        this.imageTag === 'nuxt-img' ||
        this.imageTag === 'nuxt-picture' ||
        this.loaded ||
        (!this.loaded && !this.placeholder)
      );
    },
    imageStyles() {
      const common = {
        'pointer-events': 'none',
      };
      if (!this.fallbackImageSrc) {
        return {
          ...common,
          '--fallback-content': '"No image available"',
          '--fallback-content-background-color': '#f3f3f3',
        };
      }

      return {
        ...common,
        '--fallback-content': `url("${this.fallbackImageSrc}")`,
        '--fallback-content-background-color': '#fff',
      };
    },
    attributes(): IAttributes {
      const shouldUseImageTag = this.imageTag === 'img' || this.imageTag === '';
      let attributes = null;

      if (shouldUseImageTag) {
        attributes = {
          ...this.$attrs,
          sizes: this.sizes,
          width: this.width,
          height: this.height,
          srcset: this.srcset,
        };
      } else {
        attributes = {
          ...this.$attrs,
          ...this.nuxtImgConfig,
        };
      }

      return attributes;
    },
  },
  created(): void {
    if (this.imageTag === 'nuxt-img' || this.imageTag === 'nuxt-picture') this.loaded = true;
  },
  methods: {
    onLoad(): void {
      this.loaded = true;
    },
    formatResolution(resolution: string): string {
      return `${resolution}`.endsWith('x') ? resolution : `${resolution}x`;
    },
    formatDimension(size?: IImageSize): IImageSize | undefined {
      if (!size) return;

      if (
        ['%'].includes(`${size}`.slice(-1)) ||
        ['rem'].includes(`${size}`.slice(-3)) ||
        ['em', 'px', 'vw', 'vh'].includes(`${size}`.slice(-2)) ||
        !parseInt(`${size}`, 10)
      ) {
        return size;
      } else {
        return `${size}px`;
      }
    },
    formatBreakpoint(breakpoint: IImageSize | undefined) {
      return breakpoint ? `(max-width: ${breakpoint}px) ` : '';
    },
    prefix(str: string) {
      return str ? `${str}, ` : '';
    },
    srcsetDescriptor(srcset: ISrcSetItem): string {
      return srcset.width
        ? `${Number.parseInt(srcset.width) || ''}w`
        : this.formatResolution(srcset.resolution || '');
    },
  },
});
