import { Context, Logger } from '@vue-storefront/core';
import { AxiosError } from 'axios';

import { IUseRegoVehiclesRefs } from '../types';

const getRegoVehicles =
  (context: Context, refs: IUseRegoVehiclesRefs, id: string) =>
  async (registrationNumber: string, registrationAuthority: string, refresh = false) => {
    Logger.debug(`useRegoVehicles/${id}/getRegoVehicles`);
    refs.result.value = null;
    refs.error.value = null;
    try {
      refs.loading.value = true;
      refs.result.value = await context.$rego.api.getVehiclesDetails(
        registrationNumber.toLowerCase(),
        registrationAuthority.toLowerCase(),
        refresh,
      );
      refs.error.value = null;
    } catch (error: any) {
      if (error && error.response) {
        refs.error.value = error as AxiosError;
      } else {
        refs.error.value = error as Error;
      }

      Logger.error(`useRegoVehicles/${id}/getRegoVehicles/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default getRegoVehicles;
