import { Logger } from '@vue-storefront/core';

import getCustomerAccessToken from '../../helpers/internals/getCustomerAccessToken';
import { Context } from '../../types';
import { IUseUserAddressesRefs } from '../types';

const loadUserAddresses = (context: Context, refs: IUseUserAddressesRefs) => async () => {
  const customerAccessToken = getCustomerAccessToken(context);

  Logger.debug('useUserAddresses/load');

  try {
    refs.loading.value.loadUserAddresses = true;

    refs.result.value.loadUserAddresses = await context.$shopify.api.fetchAddresses(
      customerAccessToken,
    );

    refs.error.value.loadUserAddresses = null;
  } catch (error) {
    refs.error.value.loadUserAddresses = error as Error;
    Logger.error('useUserAddresses/load/error', error);
  } finally {
    refs.loading.value.loadUserAddresses = false;
  }
};

export default loadUserAddresses;
