import { IFooterNavLinks, IFooterSocialLinks } from './types';

export const STUB_FOOTER_NAV_LINKS: IFooterNavLinks = [
  {
    label: 'ABOUT US',
    links: [
      {
        label: 'About Us',
        link: '/about/team',
      },
      {
        label: 'Sparesbox Blog',
        link: '/blog',
      },
      {
        label: 'Privacy Policy',
        link: '/about/privacy-policy',
      },
      {
        label: 'Sparesbox on Facebook',
        link: 'https://www.facebook.com/sparesbox',
      },
      {
        label: 'Terms and Conditions',
        link: '/about/legals',
      },
      {
        label: 'Pay Over Time with Afterpay',
        link: '/about/afterpay',
      },
      {
        label: 'Zip Pay',
        link: '/about/zip-pay',
      },
    ],
  },
  {
    label: 'HELP & CONTACT',
    links: [
      {
        label: 'Contact Us',
        link: '/contact',
      },
      {
        label: 'Returns',
        link: '/returns',
      },
      {
        label: 'Shipping',
        link: '/help/shipping',
      },
      {
        label: 'Fitment Guarantee',
        link: '/returns',
      },
      {
        label: 'Nationwide Delivery',
        link: '/help/shipping',
      },
      {
        label: 'FAQ',
        link: '/help/faq',
      },
      {
        label: 'Buying Guides',
        link: '/buying-guides',
      },
    ],
  },
  {
    label: 'ACCOUNT',
    links: [
      {
        label: 'Manage Account',
        link: '/account/dashboard',
      },
      {
        label: 'Login',
        link: '/account/login',
      },
      {
        label: 'Register',
        link: '/account/register',
      },
    ],
  },
  {
    label: 'SHOP',
    links: [
      {
        label: 'Performance',
        link: '/shop/performance',
      },
      {
        label: 'Offroad & 4x4',
        link: '/shop/offroad-4x4',
      },
      {
        label: 'Servicing',
        link: '/shop/servicing',
      },
      {
        label: 'Workshop',
        link: '/shop/workshop-garage',
      },
      {
        label: 'Shop By Make',
        link: '/vehicles',
      },
      {
        label: 'Shop By Category',
        link: '/parts',
      },
    ],
  },
];

export const STUB_FOOTER_SOCIAL_LINKS: IFooterSocialLinks = [
  {
    label: 'Facebook',
    link: 'https://www.facebook.com/sparesbox',
    iconSrc: '/assets/storybook/BaseFooterLinks/facebook.svg',
  },
  {
    label: 'Twitter',
    link: 'https://twitter.com/Sparesbox_auto',
    iconSrc: '/assets/storybook/BaseFooterLinks/twitter.svg',
  },
  {
    label: 'Instagram',
    link: 'https://instagram.com/sparesbox',
    iconSrc: '/assets/storybook/BaseFooterLinks/instagram.svg',
  },
  {
    label: 'YouTube',
    link: 'https://www.youtube.com/channel/UCD6DDl9VZ6-GLpfQR4CahyQ',
    iconSrc: '/assets/storybook/BaseFooterLinks/youtube.svg',
  },
];
