


















































































import Vue from 'vue';

import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseIcon from '../../atoms/BaseIcon/BaseIcon.vue';
import BaseLink from '../../atoms/BaseLink/BaseLink.vue';

export default Vue.extend({
  name: 'BasePaginationEjected',
  components: {
    BaseIcon,
    BaseLink,
    BaseButton,
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Number,
      default: 5,
    },
    hasArrows: {
      type: Boolean,
      default: true,
    },
    current: {
      type: Number,
      default: 1,
    },
    pageParamName: {
      type: String,
      default: 'page',
    },
  },
  computed: {
    hasRouter() {
      return this.$route;
    },
    currentPage() {
      return this.hasRouter
        ? this.$route.query[this.pageParamName]
          ? parseInt(this.$route.query[this.pageParamName], 10)
          : 1
        : this.current;
    },
    getPrev() {
      return this.currentPage === this.firstVisiblePageNumber
        ? this.currentPage
        : this.currentPage - 1;
    },
    canGoPrev() {
      return this.currentPage !== this.firstVisiblePageNumber;
    },
    getNext() {
      return this.currentPage === this.lastVisiblePageNumber
        ? this.currentPage
        : this.currentPage + 1;
    },
    canGoNext() {
      return this.currentPage !== this.lastVisiblePageNumber;
    },
    showFirst() {
      return this.firstVisiblePageNumber > 1;
    },
    showLast() {
      return this.lastVisiblePageNumber < this.total;
    },
    listOfPageNumbers() {
      return Array.from(Array(this.total), (_, i) => i + 1);
    },
    limitedPageNumbers() {
      if (this.total <= this.visible) {
        return this.listOfPageNumbers;
      }
      if (this.currentPage < this.visible - Math.floor(this.visible / 2) + 1) {
        return this.listOfPageNumbers.slice(0, this.visible);
      }
      if (this.total - this.currentPage < this.visible - Math.ceil(this.visible / 2) + 1) {
        return this.listOfPageNumbers.slice(this.total - this.visible);
      }
      return this.listOfPageNumbers.slice(
        this.currentPage - Math.ceil(this.visible / 2),
        this.currentPage + Math.floor(this.visible / 2),
      );
    },
    firstVisiblePageNumber() {
      return this.limitedPageNumbers[0];
    },
    lastVisiblePageNumber() {
      return this.limitedPageNumbers[this.limitedPageNumbers.length - 1];
    },
  },
  methods: {
    go(page) {
      this.$emit('click', page);
    },
    getLinkTo(page) {
      const pageNumber = page.toString();
      if (this.hasRouter) {
        return {
          ...this.$route,
          query: { ...this.$route.query, [this.pageParamName]: page },
        };
      } else {
        return pageNumber;
      }
    },
  },
});
