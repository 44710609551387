import { IAutocompleteResponse } from '@api-client/src/api/autocomplete/types';
import { ResponseStatus } from '@api-client/src/types';
import { Context, Logger } from '@vue-storefront/core';

import { IUseAutcompleteRefs } from '../types';

const query =
  (context: Context, refs: IUseAutcompleteRefs, id: string) => async (query: string) => {
    Logger.debug(`useAutocomplete/${id}/query`);

    try {
      refs.loading.value = true;

      const autocompleteResponse: IAutocompleteResponse =
        await context.$googlePlaces.api.autocomplete(query);

      refs.result.value = autocompleteResponse;
      refs.error.value = null;

      if (autocompleteResponse.status !== ResponseStatus.OK) {
        refs.error.value = new Error('No results found.');

        Logger.error(`useAutocomplete/${id}/query/error`, autocompleteResponse);
      }
    } catch (error) {
      refs.error.value = error as Error;

      Logger.error(`useAutocomplete/${id}/query/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default query;
