



























import { PropType } from 'vue';
import { ICheckboxGroupItem, IData } from './types';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseCheckbox from '../../molecules/BaseCheckbox/BaseCheckbox.vue';

export default {
  name: 'BaseCheckboxGroupItem',
  components: {
    BaseIcon,
    BaseCheckbox,
  },
  props: {
    checkbox: {
      type: Object as PropType<ICheckboxGroupItem>,
      default: (): ICheckboxGroupItem => ({
        name: 'Price',
        label: 'Price',
        value: 'price',
        trailingNumber: 500,
        selected: false,
      }),
    },
  },
  data(): IData {
    return {
      selected: this.checkbox.selected,
    };
  },
  watch: {
    // Sync with the prop `checkbox.selected` - so that the `selected` state can be driven by a parent component.
    'checkbox.selected'(): void {
      this.selected = this.checkbox.selected;
    },
  },
  methods: {
    onChange(): void {
      this.selected = !this.selected;

      this.$emit('checkbox-change', {
        ...this.checkbox,
        selected: this.selected,
      });
    },
  },
};
