import { IAutocompleteResponse } from '@api-client/src/api/autocomplete/types';

export interface IResult {
  label: string;
  value: string;
}

const getResults = (response: IAutocompleteResponse): IResult[] => {
  if (response && response.predictions.length > 0) {
    return response.predictions.map((prediction) => {
      return {
        label: prediction.description,
        value: prediction.place_id,
      };
    });
  }

  return [];
};

const autocompleteGetters = {
  getResults,
};

export default autocompleteGetters;
