import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import setNotification from './inStockNotification';
import {
  UseInStockNotificationError,
  UseInStockNotificationLoading,
  UseInStockNotificationResult
} from './types';

const useInStockNotification = (id: string) => {
  const context = useVSFContext();
  const loading = sharedRef(false, `useInStockNotification/loading-${id}`);
  const result = sharedRef(null, `useInStockNotification-${id}`);
  const error = sharedRef(null, `useInStockNotification/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    setNotification: setNotification(context, refs, id),
    loading: computed<UseInStockNotificationLoading>(() => loading.value),
    result: computed<UseInStockNotificationResult>(() => result.value),
    error: computed<UseInStockNotificationError>(() => error.value),
  };
};

export default useInStockNotification;
