import { TreeSearchMatch } from '../../helpers/utils/treeSearch';
export type resolveShopifyError = Error | null;

export type SbShopifyProduct = {
  id: string;
  sku: string;
  name: string;
  type: 'product';
  image: string;
  description: string;
  [key: string]: unknown;
};

export type SbShopifyCategory = {
  id: string;
  name: string;
  type: 'category';
  description: string;
  childCount: number;
  [key: string]: unknown;
};

export type ComponentCategory = {
  shopify_category: {
    plugin: 'sb-shopify' | 'uc-shopify-integration';
    items: SbShopifyCategory[];
  };
  component: 'ShopifyCategory';
  [key: string]: unknown;
};

export type ComponentProduct = {
  shopify_product: {
    plugin: 'sb-shopify' | 'uc-shopify-integration';
    items: SbShopifyProduct[];
  };
  component: 'ShopifyProduct';
  [key: string]: unknown;
};

export type ComponentProducts = {
  product_count: string;
  query: string;
  sort_key: string;
  component: 'ShopifyProducts';
  [key: string]: unknown;
};

export type ComponentProductsFromCategory = {
  sort_key: string;
  product_count: string;
  shopify_category: {
    plugin: 'sb-shopify' | 'uc-shopify-integration';
    items: SbShopifyCategory[];
  };
  component: 'ShopifyProductsFromCategory';
  [key: string]: unknown;
};

export type Components =
  | ComponentProduct
  | ComponentProductsFromCategory
  | ComponentCategory
  | ComponentProducts;

export const isProductComponent = (component: Components): component is ComponentProduct =>
  component.component === 'ShopifyProduct';

export const isCategoryComponent = (component: Components): component is ComponentCategory =>
  component.component === 'ShopifyCategory';

export const isProductsComponent = (component: Components): component is ComponentProducts =>
  component.component === 'ShopifyProducts';

export const isProductsFromCategoryComponent = (
  component: Components,
): component is ComponentProductsFromCategory =>
  component.component === 'ShopifyProductsFromCategory';

export const isFulfilled = <T>(
  input: PromiseSettledResult<T>,
): input is PromiseFulfilledResult<T> => input.status === 'fulfilled';

export type ComponentReference = TreeSearchMatch & { component: any; data?: any };
