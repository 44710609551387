import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import signupToNewsletter from './signupToNewsletter';
import {
  UseNewsletterSignupError,
  UseNewsletterSignupLoading,
  UseNewsletterSignupResult,
} from './types';

const useNewsletterSignup = (id: string) => {
  const context = useVSFContext();

  const loading = sharedRef(false, `useNewsletterSignup/loading-${id}`);
  const result = sharedRef(null, `useNewsletterSignup-${id}`);
  const error = sharedRef(null, `useNewsletterSignup/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    signupToNewsletter: signupToNewsletter(context, refs, id),
    loading: computed<UseNewsletterSignupLoading>(() => loading.value),
    result: computed<UseNewsletterSignupResult>(() => result.value),
    error: computed<UseNewsletterSignupError>(() => error.value),
  };
};

export default useNewsletterSignup;
