/**
 * The Storyblok integration converts the normal array of stories into an object with
 * array indexes converted to string keys => {"0":story,"1":story, ...other-non-story-data}
 * this fn gets only the stories and returns them as a real array
 * @param queryResult the original result from the integration
 * @returns Array of stories
 */
export default function getStoriesAsArray<T = any>(queryResult: Record<string, T>): T[] {
  return Object.keys(queryResult)
    .filter((key) => !isNaN(parseInt(key, 10)))
    .map((key) => queryResult[key]);
}
