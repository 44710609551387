
















import BaseBadge from '../../atoms/BaseBadge/BaseBadge.vue';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseIcon from '../../atoms/BaseIcon/BaseIcon.vue';

export default {
  name: 'BaseChip',
  components: {
    BaseBadge,
    BaseButton,
    BaseIcon,
  },
  props: {
    prefix: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      required: true,
    },
  },
  methods: {
    remove(): void {
      this.$emit('remove-chip');
    },
  },
};
