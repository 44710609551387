import { Cart, IMultipassSession } from '@api-client/src/types';
import { Context, Logger, useVSFContext } from '@vue-storefront/core';
import Multipassify from 'multipassify';

import { cartGetters } from '../getters';
import { User } from '../types';

type useMultipassParams = {
  redirectURL?: string;
  isRedirectExternal?: boolean;
};

// eslint-disable-next-line max-lines-per-function
function useMultipass() {
  const context: Context = useVSFContext();

  const createMultipassSession = (
    user: User,
    { redirectURL = '/', isRedirectExternal = false },
  ): Promise<IMultipassSession | null> => {
    return context.$shopify.api.createMultipassSession(user, {
      redirectURL: redirectURL,
      isRedirectExternal: isRedirectExternal,
    });
  };

  const getAuthenticatedCheckoutURL = async (cart: Cart, user: User): Promise<string> => {
    const cartURL = cartGetters.getcheckoutURL(cart);
    const authenticatedCheckoutURL = await getMultipassLoginUrl(user, {
      redirectURL: cartURL,
      isRedirectExternal: true,
    });
    if (!authenticatedCheckoutURL) {
      return cartURL;
    }
    return authenticatedCheckoutURL;
  };

  const getMultipassLoginUrl = async (
    user: User,
    params: useMultipassParams = { redirectURL: '/', isRedirectExternal: false },
  ): Promise<string | null> => {
    const { redirectURL, isRedirectExternal } = params;
    let multipassLoginURL: string | null = null;
    try {
      if (!user) throw new Error('Unable to get multipass login url as user argument is null');
      if (!user.token) throw new Error('Unable to get multipass login url as user token is null');

      const multipassSession = await createMultipassSession(user, {
        redirectURL: redirectURL,
        isRedirectExternal: isRedirectExternal,
      });

      if (!multipassSession) throw new Error('Failed to create multipass session');

      multipassLoginURL = multipassSession.url;
    } catch (e) {
      Logger.warn(`getMultipassLoginUrl(): ${e}`);
    }
    return multipassLoginURL;
  };

  const getMultipassToken = async (
    user: User,
    params: useMultipassParams = { redirectURL: '/', isRedirectExternal: false },
  ): Promise<string | null> => {
    const { redirectURL, isRedirectExternal } = params;
    let multiPassToken: string | null = null;
    try {
      if (!user) throw new Error('Unable to get multipass login url as user argument is null');
      if (!user.token) throw new Error('Unable to get multipass login url as user token is null');

      const multipassSession = await createMultipassSession(user, {
        redirectURL: redirectURL,
        isRedirectExternal: isRedirectExternal,
      });

      if (!multipassSession) throw new Error('Failed to create multipass session');

      multiPassToken = multipassSession.token;
    } catch (e) {
      Logger.warn(`getMultipassLoginUrl(): ${e}`);
    }
    return multiPassToken;
  };

  return {
    getMultipassLoginUrl,
    getMultipassToken,
    getAuthenticatedCheckoutURL,
  };
}

export default useMultipass;
