import { Option } from './types';

export const STUB_OPTIONS_PROP = <Array<Option>>[
  {
    label: 'Recommended',
    value: 'recommended',
  },
  {
    label: 'Lowest Price',
    value: 'lowest-price',
  },
  {
    label: 'Highest Price',
    value: 'highest-price',
  },
  {
    label: 'Brand A - Z',
    value: 'brand-asc',
  },
  {
    label: 'Brand Z - A',
    value: 'brand-desc',
  },
  {
    label: 'Name A - Z',
    value: 'name-asc',
  },
  {
    label: 'Name Z - A',
    value: 'name-desc',
  },
];
