import { ICategory, ISubCategory } from './types';

// Ref: https://www.sparesbox.com.au/parts/engine-cooling-and-drivetrain
export const STUB_CATEGORY_FILTER_DATA = <Array<ICategory>>[
  {
    id: 1,
    label: 'Auto Electrical',
    productCount: 14888,
  },
  {
    id: 2,
    label: 'Cables, Fuses & Relays',
    productCount: 10,
  },
  {
    id: 3,
    label: 'Climate Control',
    productCount: 4282,
  },
  {
    id: 4,
    label: 'Cooling System',
    productCount: 16162,
  },
  {
    id: 5,
    label: 'Diesel Particulate Filters',
    productCount: 139,
  },
  {
    id: 6,
    label: 'Engine Components',
    productCount: 29708,
  },
  {
    id: 7,
    label: 'Engine Management',
    productCount: 757,
  },
];

export const STUB_SUB_CATEGORIES_FILTER_DATA = <Array<ISubCategory>>[
  {
    categoryId: 1,
    id: 21312,
    label: 'Alternators',
    productCount: 1100,
  },
  {
    categoryId: 1,
    id: 1231,
    label: 'Sensors, Switches & Senders',
    productCount: 12647,
  },
  {
    categoryId: 1,
    id: 1516,
    label: 'Starter Motors',
    productCount: 1141,
  },
  {
    categoryId: 2,
    id: 13213,
    label: 'Relays',
    productCount: 1,
  },
  {
    categoryId: 3,
    id: 12315,
    label: 'AC Compressors',
    productCount: 201,
  },
  {
    categoryId: 3,
    id: 6156,
    label: 'Air Condensers',
    productCount: 110,
  },
  {
    categoryId: 3,
    id: 48564,
    label: 'Blower Motors',
    productCount: 14,
  },
  {
    categoryId: 6,
    id: 213213312,
    label: 'Engine Belts, Pulleys & Tensioners',
    productCount: 1100,
  },
  {
    categoryId: 6,
    id: 21312132,
    label: 'Engine Hoses',
    productCount: 1100,
  },
  {
    categoryId: 6,
    id: 2131216,
    label: 'Engine Mounts',
    productCount: 1100,
  },
];
