import { Version } from '@shared/types';
import merge from 'lodash.merge';

import { FilterOption } from './types';

const allContentOfType = (componentName: string) => {
  return {
    url: '/',
    custom: {
      filter_query: {
        component: {
          in: componentName,
        },
      },
    },
  };
};

const fromURL = (url: string) => {
  return {
    url,
  };
};

const filterQuery = (filters: FilterOption[]) => {
  const query: Record<string, Record<string, string>> = {};
  filters.forEach((filter) => {
    query[filter.target] = { in: filter.value };
  });
  return {
    custom: {
      filter_query: {
        ...query,
      },
    },
  };
};

const sortQuery = (filters: FilterOption[]) => {
  const sortQuery = filters.map((filter) => `${filter.target}:${filter.value}`).join(',');
  return {
    custom: {
      sort_by: sortQuery,
    },
  };
};

const pagination = (perPage: number, currentPage: number) => {
  return {
    custom: {
      per_page: perPage,
      page: currentPage,
    },
  };
};

const relations = (relations: string) => {
  return {
    relations,
  };
};

const compose = (version: Version, ...objects: any[]) => {
  const query = objects.reduce((previous, current) => {
    return merge(previous, current);
  });
  query.version = version;
  return query;
};

const useQueryTemplateFragments = () => {
  return { allContentOfType, filterQuery, sortQuery, compose, pagination, relations, fromURL };
};

export default useQueryTemplateFragments;
