






import Vue from 'vue';

import { focus } from '../../../utilities/directives';

interface ILink {
  to: string;
  href: string;
}

export default Vue.extend({
  name: 'BaseLink',
  directives: { focus },
  props: {
    link: {
      type: [String, Object],
      required: true,
    },
  },
  computed: {
    isExternal(): boolean {
      const isExternal = typeof this.link === 'string' && this.link.search(/(^\/|^#|^\?)/g) === -1;
      return isExternal;
    },
    isNativeLinkTag(): boolean {
      return this.isExternal || !this.$router;
    },
    urlTag(): Pick<ILink, 'href'> | Pick<ILink, 'to'> {
      return this.isNativeLinkTag ? { href: this.link } : { to: this.link || '' };
    },
    linkComponentTag(): string {
      const routerLink = this.$nuxt ? 'nuxt-link' : 'router-link';
      return this.isNativeLinkTag ? 'a' : routerLink;
    },
  },
});
