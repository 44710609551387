import { Version } from '@shared/types';
import { Context, Logger } from '@vue-storefront/core';

import {
  getByString,
  getMatches,
  getPathUpNNodes,
  IArbitraryNestedObject,
  setByString,
} from '../treeSearch';
import { IUseNestedRelationsRefs } from '../types';
import { RelationReference } from './types';
import { isFulfilled } from './types';

const resolveNestedRelations =
  (context: Context, refs: IUseNestedRelationsRefs) =>
  async (story: IArbitraryNestedObject, relations: string, version: Version) => {
    Logger.debug('useNestedRelations/resolveRelations');
    let detectedShopifyComponents = false;
    try {
      refs.loading.value = true;
      detectedShopifyComponents = await resolveData(context, story, relations, version);
      refs.error.value = null;
    } catch (error) {
      refs.error.value = error as Error;
      Logger.error('useNestedRelations/resolveRelations/error', error);
    } finally {
      refs.loading.value = false;
    }
    return detectedShopifyComponents;
  };

async function resolveData(
  context: Context,
  story: IArbitraryNestedObject,
  relations: string,
  version: Version,
) {
  const [componentKeys, relationFields] = extractComponentKeysAndFields(relations);

  const keyValuePairsToSearchFor = {
    component: componentKeys,
  };

  const referencesToComponents = getMatches(story, keyValuePairsToSearchFor);

  // get references to relations themselves
  const referencesToRelations: RelationReference[] = [];
  referencesToComponents.forEach((reference) => {
    const referenceToComponent = getPathUpNNodes(reference.index, 1);
    const component = getByString(story, referenceToComponent);
    relationFields.forEach((relationField) => {
      if (component[relationField]) {
        referencesToRelations.push({
          index: `${referenceToComponent}.${relationField}`,
          relation: component[relationField],
        });
      }
    });
  });

  // iterate over matches and create/await parallel  requests
  const referencesAndResolvedRelationData = await Promise.allSettled(
    referencesToRelations.map(async (reference) => {
      const relation = getByString(story, reference.index, 0);
      return {
        ...reference,
        data: await queryRelationships(context, relation, version),
      };
    }),
  );

  // filter out unresolved data
  const filteredReferencesAndResolvedRelationData = filterUnresolvedPromises<RelationReference>(
    referencesAndResolvedRelationData,
  );

  // insert the new data
  let detectedShopifyComponents = false;
  filteredReferencesAndResolvedRelationData.forEach((resolvedPromise) => {
    if (
      getMatches(resolvedPromise.value?.data, {
        plugin: ['sb-shopify', 'uc-shopify-integration'],
      }).length > 0
    ) {
      detectedShopifyComponents = true;
    }
    replaceDataInStory(story, resolvedPromise.value.index, resolvedPromise.value.data);
  });
  return detectedShopifyComponents;
}

async function queryRelationships(context: Context, relation: string | string[], version: Version) {
  if (Array.isArray(relation)) {
    const results = await Promise.allSettled(
      relation.map(async (id: string) => {
        return await context.$sb.api.getContent({ id: id, version: version });
      }),
    );
    const filteredResults = filterUnresolvedPromises<any>(results);
    return filteredResults.map((promise) => promise.value['0']);
  }
  if (typeof relation === 'string') {
    const result: any = await context.$sb.api.getContent({
      id: relation,
      version: version,
    });
    return result['0'];
  }
  return null;
}

function replaceDataInStory(story: IArbitraryNestedObject, path: string, data: any) {
  setByString(story, path, data, 0);
}

function extractComponentKeysAndFields(relations: string) {
  const individualRelations = relations.split(',');
  const componentNames: Set<string> = new Set();
  const relationFields: Set<string> = new Set();
  individualRelations.forEach((relation) => {
    const [componentName, relationField] = relation.split('.');
    componentNames.add(componentName);
    relationFields.add(relationField);
  });
  return [Array.from(componentNames), Array.from(relationFields)];
}

function filterUnresolvedPromises<Type>(results: PromiseSettledResult<Type>[]) {
  const filteredResults = results.filter((promise) => {
    const fulfilled = isFulfilled(promise);
    if (!fulfilled) {
      Logger.error(
        `Error resolving nested relationships from storyBlok Story in /useNestedRelations/resolveRelations: \n ${promise.reason}`,
      );
    }
    return fulfilled;
  });
  return filteredResults as PromiseFulfilledResult<Type>[];
}

export default resolveNestedRelations;
