









































export default {
  name: 'BaseLoader',
  props: {
    loading: {
      default: true,
      type: Boolean,
    },
    width: {
      default: 38,
      type: Number,
    },
    height: {
      default: 38,
      type: Number,
    },
  },
  computed: {
    hasSlottedContent(): boolean {
      return !!this.$slots.default;
    },
  },
};
