import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import loadRelatedBlogPosts from './loadRelatedBlogPosts';
import {
  UseRelatedBlogPostsError,
  UseRelatedBlogPostsLoading,
  UseRelatedBlogPostsResult,
} from './types';

const useRelatedBlogPosts = () => {
  const context = useVSFContext();

  const loading = sharedRef(false, 'useRelatedBlogPosts/loading');

  const relatedBlogPostsTitle = sharedRef('', 'useRelatedBlogPostsTitle');

  const relatedBlogPosts = sharedRef(null, 'useRelatedBlogPosts');

  const error = sharedRef(null, 'useRelatedBlogPosts/error');

  const refs = {
    relatedBlogPostsTitle,
    relatedBlogPosts,
    loading,
    error,
  };

  return {
    loadRelatedBlogPosts: loadRelatedBlogPosts(context, refs),
    relatedBlogPostsTitle: computed<string>(() => relatedBlogPostsTitle.value),
    relatedBlogPosts: computed<UseRelatedBlogPostsResult>(() => relatedBlogPosts.value),
    relatedBlogPostsLoading: computed<UseRelatedBlogPostsLoading>(() => loading.value),
    relatedBlogPostsError: computed<UseRelatedBlogPostsError>(() => error.value),
  };
};

export default useRelatedBlogPosts;
