import { IZendeskEmailBody } from '@api-client/src/api/sendZendeskEmail/types';
import { Context, Logger } from '@vue-storefront/core';

import { IUseZendeskEmailRefs } from '../types';
const sendZendeskEmail =
  (context: Context, refs: IUseZendeskEmailRefs, id: string) =>
  async (
    recaptchaToken: string,
    body: IZendeskEmailBody,
    emailAddress: string,
    subject: string,
  ) => {
    Logger.debug(`useZendeskEmail/${id}/sendZendeskEmail`);

    refs.result.value = null;
    refs.error.value = null;
    try {
      refs.loading.value = true;
      refs.result.value = await context.$inHouse.api.sendZendeskEmail(
        recaptchaToken,
        body,
        emailAddress,
        subject,
      );
      refs.error.value = null;
    } catch (error) {
      refs.error.value = error as Error;
      Logger.error(`useZendeskEmail/${id}/sendZendeskEmail/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default sendZendeskEmail;
