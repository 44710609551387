/* eslint-disable @typescript-eslint/no-explicit-any */
import { Context, useReviewFactory, UseReviewFactoryParams } from '@vue-storefront/core';

import { Review } from '../types';

const params: UseReviewFactoryParams<any, any, any> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  searchReviews: async (context: Context, params: any) => {
    console.log('Mocked: searchReviews');
    return {};
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addReview: async (context: Context, params: any) => {
    console.log('Mocked: addReview');
    return {};
  },
};

const useReview = useReviewFactory<Review, any, any>(params);

export default useReview;
