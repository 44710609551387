/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProductVariant } from '@api-client/src/types';

import enhanceProduct from './enhanceProduct';

export default function getAndEnhanceProducts(
  products: Record<string, any>,
): ProductVariant[] | undefined {
  const items = products?.edges?.map((item: any) => {
    const product: any = {
      ...item.node,
      images: item.node.images.edges.map((image: any) => image.node) as any,
      variants: item.node.variants.edges.map((variant: any) => variant.node) as any,
    };

    return product;
  });

  const enhancedProducts = enhanceProduct(items);
  return enhancedProducts;
}
