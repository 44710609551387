import { ICheckoutOrderItems } from './types';

export const STUB_CHECKOUT_ORDER_ITEMS: ICheckoutOrderItems = [
  {
    name: 'Ryco Oil Filter',
    partNumber: 'R2104P',
    quantity: 9,
    price: 10.74,
  },
  {
    name: 'NGK Iridium IX Spark Plug',
    partNumber: 'BCPR6EIX-11',
    quantity: 1,
    price: 13.09,
  },
  {
    name: 'BMC Performance Air Filter',
    partNumber: 'FB443/03',
    quantity: 1,
    price: 299.66,
  },
];
