/* eslint-disable @typescript-eslint/no-explicit-any */
import { useStoreFactory, UseStoreFactoryParams } from '@vue-storefront/core';

const params: UseStoreFactoryParams<any> = {
  async change() {
    return null;
  },
  async load() {
    return null;
  },
};

const useStore = useStoreFactory<any>(params);

export default useStore;
