

































































































































































import { PropType } from 'vue';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseNativeSelect from '../../atoms/BaseNativeSelect/BaseNativeSelect.vue';
import BasePopover from '../../molecules/BasePopover/BasePopover.vue';
import BaseCheckboxGroup from '../../molecules/BaseCheckboxGroup/BaseCheckboxGroup.vue';
import BaseChip from '../../molecules/BaseChip/BaseChip.vue';
import BaseRangeSlider from '../../molecules/BaseRangeSlider/BaseRangeSlider.vue';
import BaseSidebar from '../../organisms/BaseSidebar/BaseSidebar.vue';
import BaseAccordionItem from '../../atoms/BaseAccordionItem/BaseAccordionItem.vue';
import BaseAccordion from '../../organisms/BaseAccordion/BaseAccordion.vue';
import BaseIcon from '../../atoms/BaseIcon/BaseIcon.vue';
import { ICheckboxGroupItem } from '../../atoms/BaseCheckboxGroupItem/types';
import {
  STUB_FILTER_LIST_PROP,
  STUB_SORT_LIST_PROP,
  STUB_PRODUCTS_PER_PAGE_LIST_PROP,
} from './constants';
import { IFilterList, IOnFilterUpdateArgs, ISort, IData, IFilterSelected } from './types';

export default {
  name: 'BaseProductListingHeader',
  components: {
    BaseButton,
    BaseIcon,
    BaseNativeSelect,
    BasePopover,
    BaseCheckboxGroup,
    BaseChip,
    BaseRangeSlider,
    BaseSidebar,
    BaseAccordionItem,
    BaseAccordion,
  },
  props: {
    filters: {
      type: Array as PropType<Array<IFilterList>>,
      default: (): IFilterList[] => STUB_FILTER_LIST_PROP,
    },
    sortList: {
      type: Array as PropType<Array<ISort>>,
      default: (): ISort[] => STUB_SORT_LIST_PROP,
    },
    productsPerPageOptions: {
      type: Array as PropType<Array<string>>,
      default: (): string[] => STUB_PRODUCTS_PER_PAGE_LIST_PROP,
    },
    productsCount: {
      type: Number,
      default: 1000,
    },
    filtersSelectedByDefault: {
      type: Object as PropType<IFilterSelected>,
      default: () => null,
    },
  },
  data(): IData {
    return {
      showSidebar: false,
      filtersSelected: this.filtersSelectedByDefault || null,
    };
  },
  computed: {
    topThreeFilters(): Array<IFilterList> {
      return this.filters.slice(0, 3);
    },
  },
  methods: {
    onProductPerPageSelect(optionSelected: string): void {
      this.$emit('change-products-per-page', parseInt(optionSelected, 10));
    },
    onSortBySelect(optionSelected: string): void {
      this.$emit('change-sorting', optionSelected);
    },
    onFilterUpdate(updatedFilter: IOnFilterUpdateArgs): void {
      const { name, type, value } = updatedFilter;

      if (type === 'checkbox') {
        const checkbox = value as ICheckboxGroupItem;

        if (checkbox.selected) {
          this.filtersSelected = {
            ...this.filtersSelected,
            [checkbox.name]: {
              kind: 'checkbox',
              filterName: name,
              value: checkbox.value,
              label: checkbox.label,
            },
          };
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [checkbox.name]: _, ...remainingFilters } = this.filtersSelected;

          this.filtersSelected = remainingFilters;
        }
      } else {
        this.filtersSelected = {
          ...this.filtersSelected,
          [name]: {
            kind: 'range',
            filterName: name,
            label: name,
            value,
          },
        };
      }
      this.$emit('selected-filters', this.filtersSelected);
    },
    toggleSidebar(): void {
      this.showSidebar = !this.showSidebar;
    },
    closeSidebar(): void {
      this.showSidebar = false;
    },
    clearAll(): void {
      this.filtersSelected = null;
      this.$emit('clear-all-filters');
    },
  },
};
