










































import { PropType } from 'vue';
import clickOutside from 'vue-click-outside';
import { STUB_PRODUCT_TYPES } from '../../molecules/BaseProductTypeSelector/constants';
import { IProductTypeItem, IProductTypes } from '../../molecules/BaseProductTypeSelector/types';
import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseIcon from '../../atoms/BaseIcon/BaseIcon.vue';
import BaseSearchBar from '../../molecules/BaseSearchBar/BaseSearchBar.vue';
import BaseProductTypeSelector from '../../molecules/BaseProductTypeSelector/BaseProductTypeSelector.vue';
import BaseHeaderSearchResults from '../../molecules/BaseHeaderSearchResults/BaseHeaderSearchResults.vue';
import { IHeaderSearchValues } from './types';
import {
  ISearchResultCategoryList,
  ISearchResultProductList,
  ISearchResultVehicleList,
} from '../../molecules/BaseHeaderSearchResults/types';

export default {
  name: 'BaseHeaderSearch',
  directives: {
    clickOutside,
  },
  components: {
    BaseButton,
    BaseIcon,
    BaseSearchBar,
    BaseProductTypeSelector,
    BaseHeaderSearchResults,
  },
  props: {
    productTypes: {
      type: Object as PropType<IProductTypes>,
      default: (): IProductTypes => STUB_PRODUCT_TYPES,
    },
    productTypeSelectedByDefault: {
      type: String,
      default: 'all',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search (sku, part, part number, vehicle, etc.)',
    },
    hideResults: {
      type: Boolean,
      default: false,
    },
    searchResultsCategories: {
      type: Array as PropType<ISearchResultCategoryList>,
      default: () => [],
    },
    searchResultsProducts: {
      type: Array as PropType<ISearchResultProductList>,
      default: () => [],
    },
    searchResultsVehicles: {
      type: Array as PropType<ISearchResultVehicleList>,
      default: () => [],
    },
    allProductsResultsLink: {
      type: String,
      default: '',
    },
    noResultsContactLink: {
      type: String,
      default: '',
    },
  },
  data(): IHeaderSearchValues {
    return {
      searchValue: '',
      selectedProductType: STUB_PRODUCT_TYPES[this.productTypeSelectedByDefault],
      showSearchResults: false,
    };
  },
  methods: {
    displaySearchResults(): void {
      this.showSearchResults = this.searchValue && this.searchValue.length > 0;
    },
    onSearchInput(): void {
      this.displaySearchResults();
      this.$emit('search-input', this.searchValue);
    },
    onSearchBarClick(): void {
      this.displaySearchResults();
    },
    onSearchClick(): void {
      this.$emit('search-click');
    },
    onSelectedProductType(selectedProductType: IProductTypeItem): void {
      this.selectedProductType = selectedProductType;
      this.$emit('selected-product-type', selectedProductType);
    },
    closeSearchResults(): void {
      this.showSearchResults = false;
    },
  },
};
