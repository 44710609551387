import { SbCommonOptions, SbCommonParams } from './types';

const mapSbCommonParams = (
  commonParams: SbCommonParams & SbCommonOptions,
): Record<string, unknown> => {
  const { resolveRelations, language, version = 'published' } = commonParams;

  const params: Record<string, unknown> = { version };

  if (resolveRelations) {
    params.resolve_relations = resolveRelations;
  }

  if (language) {
    params.language = language;
  }

  return params;
};

export default mapSbCommonParams;
