import { ISearchIOAggregation, ISearchResponse } from '../types';

const getVehicleSelectionYearOptions = (response: ISearchResponse) => {
  const yearFromAggregation = response?.aggregateFilters?.[
    'count.YearFrom'
  ] as ISearchIOAggregation;
  const yearToAggregation = response?.aggregateFilters?.['count.YearTo'] as ISearchIOAggregation;

  const yearFromObject = yearFromAggregation?.count?.counts || {};
  const yearToObject = yearToAggregation?.count?.counts || {};

  // convert to number array
  const yearFromArray = Object.keys(yearFromObject).map(Number);
  const yearToArray = Object.keys(yearToObject).map(Number);

  const currentYear = new Date().getFullYear();

  // edge case where if yearTo is `0` which implies ongoing production, replace zero with current year before generating pairs
  const zeroIndex = yearToArray.findIndex((year) => year === 0);

  if (zeroIndex !== -1) {
    yearToArray[zeroIndex] = currentYear;
  }

  const yearsArray = [];

  // creates years pair
  for (let index = 0; index < yearToArray.length; index++) {
    if (yearFromArray[index] && yearToArray[index]) {
      yearsArray[index] = [yearToArray[index], yearFromArray[index]];
    }
  }

  // yearFrom would have more years without pairs which implies ongoing production
  const yearsWithNoPairs = yearFromArray.slice(yearToArray.length).sort((a, b) => a - b);

  const yearsList: number[] = [];
  for (let index = 0; index < yearsArray.length; index++) {
    for (let i = yearsArray[index][0]; i >= yearsArray[index][1]; i--)
      if (!yearsList.includes(i)) {
        yearsList.push(i);
      }
  }

  if (yearsWithNoPairs.length) {
    for (let year = yearsWithNoPairs[0]; year <= currentYear; year++) {
      if (!yearsList.includes(year)) {
        yearsList.push(year);
      }
    }
  }

  const validYears = yearsList.filter((year) => {
    if (year > 1900) {
      return year;
    }
  });

  return validYears
    .sort((a, b) => a - b)
    .map((key: number) => {
      return {
        title: key.toString(),
        value: key.toString(),
      };
    });
};

export default getVehicleSelectionYearOptions;
