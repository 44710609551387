/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection } from '@apollo/src/types';
import { Context, useCategoryFactory, UseCategoryFactoryParams } from '@vue-storefront/core';

const params: UseCategoryFactoryParams<Collection, any> = {
  categorySearch: async (context: Context, params: any) => {
    return context.$shopify.api.searchCollections(params);
  },
};

const useCategory = useCategoryFactory<Collection, any>(params);

export default useCategory;
