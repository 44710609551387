import { ApiContext, StoryblokClientConfig } from '@shared/types';
import { apiClientFactory } from '@vue-storefront/core';
import StoryblokClient from 'storyblok-js-client';

import { getContent, getLinks, getAllContent } from './api';
import { ApiError, setupLogger } from '@unified-commerce/gpc-api-helper';
let storyblokClient: StoryblokClient;

const setup = ({
  token,
  previewToken,
  cacheProvider,
  rateLimit,
  maxRetries,
  timeout,
}: StoryblokClientConfig): ApiContext => {
  setupLogger();

  if (!storyblokClient) {
    storyblokClient = new StoryblokClient({
      accessToken: token,
      cache: cacheProvider,
      rateLimit,
      maxRetries,
      timeout,
    });
  }

  return {
    client: storyblokClient,
    config: {
      token,
      previewToken,
      cacheProvider,
    },
  };
};

const { createApiClient } = apiClientFactory({
  onCreate: setup,
  api: {
    getContent,
    getLinks,
    getAllContent,
  },
});

export { ApiError };
export { createApiClient };
