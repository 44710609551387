import { Content, Image } from '../types';

export const buildImageObject = (
  items: Image[],
  actions: { replace: boolean } = { replace: false },
): Image[] | Image => {
  return items.map((item: { image: any }) => {
    const { replace } = actions;
    if (item?.image) {
      const image = item.image;
      const itemObject = replace
        ? { ...item, url: image }
        : {
            ...item,
            image: {
              url: image,
            },
          };
      if (replace) delete itemObject.image;
      return itemObject;
    }
    return item;
  });
};

export const extractNestedComponents: (data: any, stories?: boolean) => any = (
  data: Content,
  stories = false,
) => {
  if (data.content) {
    extractNestedComponents(data.content);
    if (Array.isArray(data.content)) {
      data.content = data.content.map((component) => {
        extractNestedComponents(component);
        if (component.content) {
          const { content, _meta, ...metaData } = component; // bandaid fix for unintended effect where map is run multiple times on an array and causes nested _meta entries
          return {
            component: component.component,
            content: content,
            _meta: _meta ? _meta : metaData,
          };
        }
        return component;
      });
    }
  }
  const { content, _meta, ...metaData } = data as any;
  return {
    ...content,
    _meta: !stories ? (_meta ? _meta : metaData) : null,
  };
};
