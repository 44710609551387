import { IProductSummary } from './types';

export const DEFAULT_THUMBNAIL =
  'https://products-placeholder-images.sparesbox.com.au/placeholder-small.jpeg';

export const STUB_PRODUCT_LISTING_DATA = <Array<IProductSummary>>[
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 1,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/12458.1781-2020911_02410.jpg',
    id: 2,
    name: 'Zimmerman OE Coat Z Brake Rotor Front Pair',
    partNumber: '100.1249.20',
    productUrl: '/part/zimmermann-oe-coat-z-brake-rotor-front-pair-100-1249-20',
    thumbnailUrl:
      'https://sb-images-product-small.sparesbox.com.au/zimmermann-rotor-standardpair-generic-1.jpg',
    promo: 'ROTOR DEAL',
    price: '226.91',
    wasPrice: '266.95',
    discountAmount: '40.04',
    fitmentPosition: 'Front',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 3,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/fuelmiser-20180918_1537248548.png',
    id: 4,
    name: 'Fuelmiser Fuel Pump Electric',
    partNumber: 'FPE-150',
    price: '79.95',
    productUrl: '/part/fuel-pump-electric-fpe-150',
    thumbnailUrl: 'https://parts-images-product-small.sparesbox.com.au/10066-FPE-150-1-FPE-150.jpg',
    fitmentPosition: 'In Tank Pump LP',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 5,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 6,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/fuelmiser-20180918_1537248548.png',
    id: 7,
    name: 'Fuelmiser Fuel Injector',
    partNumber: 'FIJ-525',
    price: '305.95',
    productUrl: '/part/fuelmiser-fuel-injector-fij-525',
    thumbnailUrl: '',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 8,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 9,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/fuelmiser-20180918_1537248548.png',
    id: 10,
    name: 'Fuelmiser Fuel Pump EFI In Tank',
    partNumber: 'FPE-289',
    price: '140.95',
    productUrl: '/part/fuelmiser-fuel-pump-efi-in-tank-fpe-289',
    thumbnailUrl: 'https://parts-images-product-small.sparesbox.com.au/10066-FPE-289-1-FPE-289.jpg',
    fitmentPosition: 'Internal HTP 44mm - Pump Only - Suits Original Hanger Assembly',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 11,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 12,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 13,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 14,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 15,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 16,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 17,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 18,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 19,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 20,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 21,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 22,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 23,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 24,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
  {
    brandLogoUrl:
      'https://sb-categories.sparesbox.com.au/category-brands-logoresized/tridon-20180918_1537250589.png',
    id: 25,
    name: 'Tridon Emergency Cap',
    partNumber: 'RFC082',
    price: '7.95',
    productUrl: '/part/tridon-emergency-cap-rfc082',
    thumbnailUrl: 'https://sb-images-product-small.sparesbox.com.au/tridon-106296-rfc082-1.jpg',
  },
];
