












































import { PropType } from 'vue';
import BaseLink from '../../atoms/BaseLink/BaseLink.vue';
import BaseMaterialIcon from '../../atoms/BaseMaterialIcon/BaseMaterialIcon.vue';
import { STUB_ACCOUNT_LINKS } from './constants';
import { IAccountLinks, IAccountLinkItem } from './types';

export default {
  name: 'BaseHeaderAccountLinks',
  components: {
    BaseMaterialIcon,
    BaseLink,
  },
  props: {
    accountLinks: {
      type: Array as PropType<IAccountLinks>,
      default: (): IAccountLinks => STUB_ACCOUNT_LINKS,
    },
    logoutLink: {
      type: String,
      default: '#',
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    userFirstName: {
      type: String,
      default: 'Sparesbox',
    },
  },
  methods: {
    shouldShowLink(accountLink: IAccountLinkItem): boolean {
      return (
        (this.isLoggedIn && accountLink.showIfLoggedIn) ||
        (!this.isLoggedIn && !accountLink.showIfLoggedIn) ||
        accountLink.alwaysShow
      );
    },
  },
};
