



















import BaseButton from '../../atoms/BaseButton/BaseButton.vue';
import BaseBadge from '../../atoms/BaseBadge/BaseBadge.vue';
import BaseMaterialIcon from '../../atoms/BaseMaterialIcon/BaseMaterialIcon.vue';

export default {
  name: 'BaseHeaderCartLink',
  components: {
    BaseButton,
    BaseMaterialIcon,
    BaseBadge,
  },
  props: {
    numberOfItems: {
      type: Number,
      default: 0,
    },
    cartLink: {
      type: String,
      default: '#',
    },
  },
};
