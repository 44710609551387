import { IGetPlaceDetailsResponse } from '@api-client/src/api/getPlaceDetails/types';
import { ResponseStatus } from '@api-client/src/types';
import { Context, Logger } from '@vue-storefront/core';

import { IUsePlaceDetailsRefs } from '../types';

const getPlaceDetails =
  (context: Context, refs: IUsePlaceDetailsRefs, id: string) => async (placeId: string) => {
    Logger.debug(`usePlaceDetails/${id}/getPlaceDetails`);

    try {
      refs.loading.value = true;
      refs.result.value = null;
      refs.error.value = null;

      const placeDetailsResponse: IGetPlaceDetailsResponse =
        await context.$googlePlaces.api.getPlaceDetails(placeId);

      refs.result.value = placeDetailsResponse;

      if (placeDetailsResponse.status !== ResponseStatus.OK) {
        refs.error.value = new Error('No results found.');

        Logger.error(`useAutocomplete/${id}/query/error`, placeDetailsResponse);
      }
    } catch (error) {
      refs.error.value = error as Error;

      Logger.error(`useAutocomplete/${id}/query/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default getPlaceDetails;
