import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import query from './query';
import { UseAutocompleteError, UseAutocompleteLoading, UseAutocompleteResult } from './types';

const useAutocomplete = (id: string) => {
  const context = useVSFContext();

  const loading = sharedRef(false, `useAutocomplete/loading-${id}`);
  const result = sharedRef(null, `useAutocomplete-${id}`);
  const error = sharedRef(null, `useAutocomplete/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    query: query(context, refs, id),
    loading: computed<UseAutocompleteLoading>(() => loading.value),
    result: computed<UseAutocompleteResult>(() => result.value),
    error: computed<UseAutocompleteError>(() => error.value),
  };
};

export default useAutocomplete;
